import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { doc, setDoc, getDoc, onSnapshot, query, collection, orderBy, limit, getDocs } from "firebase/firestore";
import { db } from '../../firebase';
import '../../App.css';
import moment from 'moment';
import { Link, Navigate } from 'react-router-dom';
import Interaction7 from './interactions/Interaction7Host';

const Host = ({ setGameActive, currentQuestionIndex, setCurrentQuestionIndex, totalQuestions }) => {
    const [showAnswers, setShowAnswers] = useState(false);
    const [activeInteraction, setActiveInteraction] = useState(0)

    useEffect(() => {
        const gameStateDocRef = doc(db, 'gameState', 'current');
        const unsubscribe = onSnapshot(gameStateDocRef, (doc) => {
            if (doc.exists()) {
                const gameStateData = doc.data();
                setShowAnswers(gameStateData.showAnswers);
                setActiveInteraction(gameStateData.activeInteraction)
            }
        });

        return () => unsubscribe();
    }, []);



    const startGame = async () => {
        try {
            const gameStateDocRef = doc(db, 'gameState', 'current');
            await setDoc(gameStateDocRef, {
                gameActive: true,
                currentQuestionIndex: 0,
                showEndModal: false,
                showRanking: false,
                showAnswers: false, // Reseteamos el estado de respuestas
            }, { merge: true });
            setGameActive(true);
            setCurrentQuestionIndex(0);
        } catch (error) {
            console.error("Error al activar el juego: ", error);
        }
    };



    const handleNextQuestion = async () => {
        try {
            const gameStateDocRef = doc(db, 'gameState', 'current');
            await setDoc(gameStateDocRef, { showRanking: false, showAnswers: false }, { merge: true });

            const gameStateDoc = await getDoc(gameStateDocRef);
            if (gameStateDoc.exists()) {
                const gameStateData = gameStateDoc.data();
                console.log(`${currentQuestionIndex}, ${totalQuestions - 1}`)
                if (gameStateData.currentQuestionIndex + 1 > totalQuestions - 1) {
                    console.log("Completaste todas las preguntas.");

                    // Actualizar Firestore para mostrar el ranking al finalizar todas las preguntas
                    await setDoc(gameStateDocRef, {
                        showRanking: true,  // Activar el ranking
                        interactionComplete: true  // Indicar que la interacción ha terminado
                    }, { merge: true });



                    await setDoc(gameStateDocRef, { showEndModal: true }, { merge: true });
                    console.log("No hay más preguntas disponibles, se debe mostrar el modal de finalización.");

                } else {
                    const nextQuestionIndex = gameStateData.currentQuestionIndex + 1;
                    await setDoc(gameStateDocRef, { currentQuestionIndex: nextQuestionIndex, startTime: moment().toISOString() }, { merge: true });
                    setCurrentQuestionIndex(nextQuestionIndex);


                }

                if (currentQuestionIndex + 1 <= totalQuestions - 1) {

                } else {
                }
            }
        } catch (error) {
            console.error("Error al pasar a la siguiente pregunta: ", error);
        }
    };

    const handleNextLottery = async () => {
        try {
            const gameStateDocRef = doc(db, 'gameState', 'current');
            await setDoc(gameStateDocRef, { showRanking: false, showAnswers: false }, { merge: true });

            const gameStateDoc = await getDoc(gameStateDocRef);
            if (gameStateDoc.exists()) {
                const gameStateData = gameStateDoc.data();

                if (gameStateData.currentPrizeIndex + 1 > 6) {

                    // Actualizar Firestore para mostrar el ranking al finalizar todas las preguntas
                    await setDoc(gameStateDocRef, {
                        // Activar el ranking
                        interactionComplete: true  // Indicar que la interacción ha terminado
                    }, { merge: true });

                    await setDoc(gameStateDocRef, { showEndModal: true }, { merge: true });


                } else {
                    const nextPrizeIndex = gameStateData.currentPrizeIndex + 1;
                    await setDoc(gameStateDocRef, { currentPrizeIndex: nextPrizeIndex, startTime: moment().toISOString() }, { merge: true });


                }

            }
        } catch (error) {
            console.error("Error al pasar a la siguiente pregunta: ", error);
        }
    };

    const handleEndInteraction4 = async () => {
        try {
            const gameStateDocRef = doc(db, 'gameState', 'current');

            // Actualizamos el estado de la interacción para impedir que más jugadores participen
            await setDoc(gameStateDocRef, {
                interactionComplete: true, // Indicamos que la interacción ha finalizado
                showRanking: true           // Activar el ranking en Display.js
            }, { merge: true });

            // Crear la consulta para obtener los documentos ordenados por 'timeTaken' en orden ascendente
            const timesQuery = query(
                collection(db, 'user_times'),
                orderBy('timeTaken', 'asc')
            );

            // Obtener los documentos con la consulta ordenada
            const timesSnapshot = await getDocs(timesQuery);

            // Mapear los documentos para obtener los datos con el ID
            const timesArray = timesSnapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id
            }));


        

            // Asignación de puntos según la clasificación
            const pointsArray = [1000, 800, 650, 550, 475, 400, 350, 300, 260, 230, 205, 185, 170, 155, 140, 130, 120, 110, 100, 90, 80, 75, 70, 65, 60, 55, 50, 45, 40, 35];


            // Asignar puntos a los usuarios con base en sus posiciones
            for (let i = 0; i < timesArray.length; i++) {
                const userRef = doc(db, 'user_times', timesArray[i].id);
                const points = pointsArray[i] || 35; // Si hay más de 30 jugadores, asignar 35 puntos a los extras
                console.log(`${timesArray[i].timeTaken} - ${points}`)
                await setDoc(userRef, {
                    points: points
                }, { merge: true });
            }

            console.log('Interacción 4 finalizada, puntos asignados, y ranking activado.');
        } catch (error) {
            console.error('Error al finalizar la interacción 4: ', error);
        }
    };



    const handleShowRanking = async () => {
        try {
            const gameStateDocRef = doc(db, 'gameState', 'current');
            await setDoc(gameStateDocRef, { showRanking: true }, { merge: true });
            console.log("Ranking visible en display.js");
        } catch (error) {
            console.error("Error al mostrar el ranking: ", error);
        }
    };


    const [actualInteractionIndex, setActualInteraction] = useState(null)
    const showInteraction = async (num) => {
        const ref = doc(db, 'gameState', 'current');
        await setDoc(ref, {
            gameActive: true,
            currentQuestionIndex: 0,
            activeInteraction: num,
            showEndModal: false,
            showRanking: false,
            showAnswers: false,
            showMenu: false,
            interactionComplete: false,
            startTime: moment().toISOString(),
            currentPrizeIndex: -1,
            lotteryWinners: [],
            interaction4Complete: false,
            // Reseteamos el estado de respuestas
        }, { merge: true });
        setActualInteraction(num)
        console.log(num)
        setGameActive(true);
        setCurrentQuestionIndex(0);
    };

    const [menu, setMenu] = useState(false);
    const showMenu = async () => {
        const ref = doc(db, 'gameState', 'current');
        await setDoc(ref, {
            gameActive: true,
            currentQuestionIndex: 0,
            activeInteraction: activeInteraction,
            showEndModal: false,
            showRanking: false,
            showAnswers: false,
            showMenu: true
        }, { merge: true });
        setGameActive(true);
        setMenu(!menu)
    };

    const showChat = () => {
        Navigate('/host/chat')
    }


    return (
        <div className="host-button-container">
            <Button className="host-button" onClick={() => showInteraction(0)}>
                Reiniciar
            </Button>
            <Button className="host-button" onClick={() => showInteraction(1)}>
                Iniciar Interacción 1
            </Button>
            <Button className="host-button" onClick={handleNextQuestion}>
                Siguiente Pregunta
            </Button>
            <Button className="host-button" onClick={() => showInteraction(2)}>
                Iniciar Interacción 2
            </Button>
            <Button className="host-button" onClick={() => showInteraction(3)}>
                Iniciar Interacción 3
            </Button>
            <Button className="host-button" onClick={() => showInteraction(4)}>
                Iniciar Interacción 4
            </Button>
            <Button className="host-button" onClick={handleEndInteraction4}>
                Terminar Interaccion4
            </Button>
            <Button className="host-button" onClick={() => showInteraction(5)}>
                Iniciar Interacción 5
            </Button>
            <Button className="host-button" onClick={() => showInteraction(6)}>
                Iniciar Interacción 6
            </Button>
            <Button className="host-button" onClick={() => showInteraction(7)}>
                Iniciar Interacción 7
            </Button>

            {actualInteractionIndex == 7 ?
                <Interaction7/>
                : ""}

            {showAnswers && (
                <Button className="host-button" onClick={handleShowRanking}>
                    Ver Ranking
                </Button>
            )}
                        <Button className="host-button" onClick={() => showInteraction(8)}>
                Iniciar Encuesta final
            </Button>

            <Button className="host-button" onClick={() => showMenu()}>
                Mostrar menu
            </Button>


            <Link to="/host/chat" style={{ width: '100%' }}><Button className="host-button" >
                Ver Chat
            </Button></Link>

        </div>
    );
};

export default Host;
