import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'

function Home() {
    const navigate = useNavigate(); 

    const goToNextScreen = () => {
        const uinfo = localStorage.getItem("userInfo");
        if (uinfo) {
            //Usuario existe
            navigate("/waiting")
        }else{
            navigate("/login")
        }
    }

    return (
        <div className='content gradient-backHome ps-0 pe-0'>

            <Row style={{ height: '80dvh' }} className='m-0'>
                <Col className='flex ps-0 pe-0 pe-0' style={{ flexDirection: 'column', justifyContent: 'space-between' }}>
                    <Row className='pt-3 ps-3 pe-3 m-0'>
                        <Col className='flex a-center pe-0 ps-0'>
                             <img style={{ height: '40px' }} src={'/imgs/caam_negro.png'} /> 
                        </Col>
                        <Col className='endRow a-center ps-0 pe-0'>
                            <img style={{ height: '65px' }} src={'/imgs/md_sticker.png'} />
                        </Col>
                    </Row>
                    <div className='ps-4 pe-4'>
                        <p style={{lineHeight:'24px', letterSpacing:'2px'}} className='fw800 fs-22 mb-1'>BIENVENIDOS AL</p>
                        <img style={{ width: '95%' }} src="/imgs/media_day_logo.png" />
                        <p className='fs-22 mb-0' style={{lineHeight:'22px'}}><span className='fw800'>RE EVOLUCIÓN</span> DE LOS MEDIOS</p>
                        <div className='fs-14 flex ' style={{ justifyContent: 'space-between' }}>
                            <div className='fitContentWidth fw800'>22 DE OCTUBRE</div>
                            <div className='fitContentWidth'>TATTERSALL <span className='fw800'>SAN ISIDRO</span></div>
                        </div>
                    </div>
                    <div className='flex j-center'>
                        <img style={{ height: '80vw', marginBottom: '-1px' }} src={"/imgs/personajes.png"} />
                        <div style={{ position: 'absolute', bottom: '45%' }}>
                            <Button bg='' onClick={goToNextScreen} style={{ backgroundColor: 'black', borderRadius: '20px', border: '0px' }} className='boxShadowButton fw600 ps-3 pe-3'>
                                <div className='fw600 ps-3 pe-3 fs-18'>INGRESAR</div>
                            </Button>
                        </div>
                    </div>

                </Col>


            </Row>
            <Row style={{ backgroundColor: 'black', height: '20dvh' }} className='ps-4 pe-4 m-0'>
                <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} className='txtWhite'>
                <p className='txtWhite mb-1 fw200' style={{ textAlign: 'left' }}>Powered by</p>
                    <div style={{ height: 'fit-content', textAlign: 'left' }} >
                          <img style={{ height: '30px', fill:'white' }} src="/imgs/logo_logan.png" /> 
                    </div>
                </Col>
                <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <p className='txtWhite mb-1 fw200' style={{ textAlign: 'right' }}>Sponsor <span className='fw800'>Oro</span></p>
                    <div style={{ height: 'fit-content' }} className='endRow'>
                        <img style={{ height: '60px' }} src="/imgs/ma_white.png" />
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Home