import React, { useState, useEffect, useRef } from 'react';
import { collection, getDocs, doc, onSnapshot, query, orderBy, limit } from 'firebase/firestore';
import { db } from '../../firebase';
import { Col, Row, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'; // Importamos useNavigate

const Display = () => {
    const [gameActive, setGameActive] = useState(false);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [questions, setQuestions] = useState([]);
    const [currentQuestion, setCurrentQuestion] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const [timeLeft, setTimeLeft] = useState(10);
    const [showAnswers, setShowAnswers] = useState(false);
    const [activeInteraction, setActiveInteraction] = useState(0);
    const [lotteryWinners, setLotteryWinners] = useState([]);
    const [lotteryStatus, setLotteryStatus] = useState('');
    const [winnersRevealed, setWinnersRevealed] = useState(0); // Controla cuántos ganadores se han mostrado
    const [messages, setMessages] = useState([]);
    const messagesEndRef = useRef(null);
    const navigate = useNavigate();

    // Escuchar cambios en el estado del juego y la interacción activa
    useEffect(() => {
        const gameStateDocRef = doc(db, 'gameState', 'current');
        const unsubscribe = onSnapshot(gameStateDocRef, async (doc) => {
            if (doc.exists()) {
                const gameStateData = doc.data();
                setGameActive(gameStateData.gameActive);
                setCurrentQuestionIndex(gameStateData.currentQuestionIndex);
                setActiveInteraction(gameStateData.activeInteraction);

                // Redirigir al ranking si corresponde
                if (gameStateData.showRanking) {
                    navigate('/ranking');
                    return;  // Detener la lógica aquí si estamos mostrando el ranking
                }



           

                // Si el sorteo ha finalizado, obtener los ganadores de los primeros 7 jugadores del ranking
                if (gameStateData.lotteryStatus === 'finalizado') {
                    const fetchWinners = async () => {
                        const q = query(collection(db, 'user_responses'), orderBy('score', 'desc'), limit(7));
                        const winnersSnapshot = await getDocs(q);
                        const winners = winnersSnapshot.docs.map(doc => ({
                            nickname: doc.data().nickname,
                            lastname: doc.data().lastname,
                            prize: doc.data().prize
                        }));
                        setLotteryWinners(winners);
                    };
                    fetchWinners();
                }
            }
        });
        return () => unsubscribe();
    }, [navigate]);



    // Obtener las preguntas
    useEffect(() => {
        const fetchQuestions = async () => {
            const collectionName = activeInteraction === 3 ? 'questions_8888' : 'question_boolean';
            const questionsCollection = collection(db, collectionName);
            const questionsSnapshot = await getDocs(questionsCollection);
            const questionsList = questionsSnapshot.docs.map(doc => doc.data());
            setQuestions(questionsList);
        };
        fetchQuestions();
    }, [activeInteraction]);

    // Actualizar la pregunta actual
    useEffect(() => {
        if (questions.length > 0 && currentQuestionIndex < questions.length) {
            setCurrentQuestion(questions[currentQuestionIndex]);
            setSelectedOption(null); // Reiniciar la opción seleccionada
            setTimeLeft(10); // Reiniciar el tiempo cuando se carga una nueva pregunta
            setShowAnswers(false); // Ocultar respuestas cuando se carga una nueva pregunta
        }
    }, [questions, currentQuestionIndex]);

    // Manejar el temporizador
    useEffect(() => {
        if (timeLeft > 0) {
            const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
            return () => clearTimeout(timer);
        } else {
            setShowAnswers(true); // Mostrar las respuestas cuando el tiempo se acaba
        }
    }, [timeLeft]);

    // Suscribirse a los mensajes en tiempo real, ordenados por timestamp (para el chat)
    useEffect(() => {
        const q = query(collection(db, 'chat_messages'), orderBy('timestamp', 'asc'));
        const unsubscribe = onSnapshot(q, (snapshot) => {
            const updatedMessages = snapshot.docs.map(doc => doc.data());
            setMessages(updatedMessages);
            scrollToBottom(); // Autoscroll al final cuando llegan nuevos mensajes
        });

        return () => unsubscribe(); // Limpiar la suscripción al desmontar el componente
    }, []);

    // Función para desplazar el scroll al final (chat)
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const colorUsers = ['#1cf100', '#06dae5'];

    if (!gameActive) {
        return (
            <div className="content">
                <div className="waiting-message flex-column flex a-center j-center" style={{ height: '100vh', backgroundColor: 'black' }}>
                    <Row className='pt-4'>
                        <Col className='flex j-center'>
                            <div className='fitContentWidth' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <img style={{ height: '90px' }} src="/imgs/media_day_logo.png" alt="Media Day Logo" />
                                <p className='txtWhite me-1 mb-0' style={{ color: 'white', marginTop: '20px', textAlign: 'center', fontSize: '30px' }}>
                                    Esperando que el host inicie el juego...
                                </p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }

    if (!currentQuestion || !currentQuestion.pregunta || !currentQuestion.opciones) {
        return <div>Cargando pregunta...</div>;
    }

    // Si la interacción es 6, mostrar el chat en vivo sin la opción de enviar mensajes
    if (activeInteraction === 6) {
        return (
            <div className='content'>
                <Row className='pt-4'>
                    <Col className='flex j-center'>
                        <div className='fitContentWidth'>
                            <img style={{ height: '35px' }} src="/imgs/media_day_logo.png" alt="logo" />
                            <p className='txtWhite mt-1 fs-18 me-1 mb-0' style={{ textAlign: 'right' }}>let's chat!</p>
                        </div>
                    </Col>
                </Row>

                {/* Área del chat */}
                <div style={{ width: '100%', height: 'calc(100vh - 160px)', overflowY: 'auto', marginBottom: '20px' }}>
                    {messages.map((msg, index) => (
                        <div key={index} style={{ padding: '5px' }} className='txtWhite'>
                            <strong style={{ color: index % 2 === 0 ? colorUsers[0] : colorUsers[1] }}>{msg.user}</strong> {msg.message}
                        </div>
                    ))}
                    <div ref={messagesEndRef} />  {/* Ref para el autoscroll al final */}
                </div>
            </div>
        );
    }
    if (activeInteraction === 2 || activeInteraction === 4 || activeInteraction === 5) {
        return (
            <div className='content flex a-center j-center' style={{flexDirection:'column'}}>
                <Row className='pt-4'>
                    <Col className='flex j-center'>
                        <div className='fitContentWidth'>
                            <img style={{ width: '50vw' }} src="/imgs/media_day_logo.png" alt="logo" />
                            <p className='txtWhite mt-1 fs-18 me-1 mb-0' style={{ textAlign: 'right' }}>let's play!</p>
                        </div>
                    </Col>
                </Row>

                <div className={`radiusButton noBorder pt-1 pb-1 mb-3 gradient-green txt-center mt-4`} style={{ width: '50vw' }}>
                    <div className='fs-22 fw600 ' style={{ color: 'black' }}>
                        {activeInteraction === 2 ? '¡Giremos!' : activeInteraction === 4 ? 'Minigame' :  activeInteraction === 5 ? 'GEN IA' : ''}
                    </div>
                </div>

                {/* Área del chat */}

            </div>
        );
    }
    // Mostrar "Realizando sorteo" si el sorteo está en progreso
    if (lotteryStatus === 'realizando') {
        return (
            <div className="content">
                <h2>Realizando Sorteo...</h2>
            </div>
        );
    }

    // Mostrar ganadores uno por uno, a medida que son revelados
    if (lotteryStatus === 'finalizado' && winnersRevealed > 0) {
        return (
            <div className="content">
                <h2>¡Ganadores del Sorteo!</h2>
                <Row>
                    {lotteryWinners.slice(0, winnersRevealed).map((winner, index) => (
                        <Col key={index} xs={12} className="winner-col">
                            <p>
                                <strong>{winner.nickname} {winner.lastname}</strong>
                            </p>
                        </Col>
                    ))}
                </Row>
            </div>
        );
    }

    // Si la interacción es 3, aplicamos los estilos y la lógica de `Interaction3`
    if (activeInteraction === 3) {
        return (
            <div className="questions-page" style={{ backgroundColor: '#FFE600', height: '100vh' }}>
                <Row className='pt-4'>
                    <Col className='flex j-center'>
                        <div className='fitContentWidth'>
                            <img style={{ height: '35px' }} src="/imgs/media_day_negro.png" alt="logo" />
                            <p className=' mt-1 fs-18 me-1 mb-0' style={{ textAlign: 'right' }}>let's play!</p>
                        </div>
                    </Col>
                </Row>

                <Row className="text-center ps-3 pe-3">
                    <Col>
                        <Row className='mb-3 ps-3 pe-3' style={{ height: '80px' }}>
                            <Col>
                                <div style={{ position: 'relative' }} className='ps-0 pe-0'>
                                    <div className='flex j-center'>
                                        <img src="/imgs/ma_color.png" style={{ height: '80px' }} alt="timer" />
                                    </div>
                                    <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
                                        <div style={{ position: 'relative' }} className='ps-0'>
                                            <div className='fitContentWidth'>
                                                <img style={{ width: '50px' }} className='br-5' src="/imgs/timer2.png" alt="timer" />
                                            </div>
                                            <div className='fw800 txt-center ' style={{
                                                position: 'absolute',
                                                bottom: 4,
                                                left: 7.5,
                                                width: '35px',
                                                height: '35px',
                                                borderRadius: '50%',
                                                backgroundColor: '#7e61d8',
                                                fontSize: '25px'
                                            }}>
                                                {timeLeft}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <div style={{ position: 'relative', backgroundColor: 'white', borderRadius: '25px 0 0 25px' }} className='flex'>
                            <div className='txt-center flex a-center j-center fw800 fs-22' style={{
                                height: '50px',
                                width: '50px',
                                backgroundColor: '#7e61d8',
                                borderRadius: '50%',
                                position: 'absolute',
                                left: '0'
                            }}>
                                {currentQuestionIndex + 1}
                            </div>
                            <div style={{ paddingLeft: '50px' }} className='pe-2 '>
                                {currentQuestion.pregunta}
                            </div>
                        </div>

                        <Row className="mt-3">
                            {currentQuestion.opciones.map((option, index) => (
                                <div className="mb-3" key={index} style={{ backgroundColor: 'white', position: 'relative', borderRadius: '25px 0 0 25px' }}>
                                    <div className='txt-center flex a-center j-center fw800 fs-22' style={{
                                        height: '38px',
                                        width: '38px',
                                        backgroundColor: '#7e61d8',
                                        borderRadius: '50%',
                                        position: 'absolute',
                                        left: '0',
                                        opacity: showAnswers ? (currentQuestion.respuesta === option ? 1 : 0.5) : 1 // Atenuar incorrectas cuando se muestran las respuestas
                                    }}>
                                        {String.fromCharCode(65 + index)} {/* Letras A, B, C... */}
                                    </div>
                                    <Button
                                        className={`w-100 noBorder txtBlack`}
                                        style={{
                                            opacity: showAnswers ? (currentQuestion.respuesta === option ? 1 : 0.5) : 1, // Atenuar incorrectas
                                            backgroundColor: 'transparent',
                                            pointerEvents: 'none' // Deshabilitar selección
                                        }}
                                    >
                                        <div className='txtBlack'>
                                            {option}
                                        </div>
                                    </Button>
                                </div>
                            ))}
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }

    // Si la interacción NO es 3 o 6, aplicamos los estilos anteriores y permitimos la selección
    return (
        <div className="content">
            {/* Encabezado con el logo */}
            <Row className='pt-4'>
                <Col className='flex j-center'>
                    <div className='fitContentWidth'>
                        <img style={{ height: '35px' }} src="/imgs/media_day_logo.png" alt="logo" />
                        <p className='txtWhite mt-1 fs-18 me-1 mb-0' style={{ textAlign: 'right' }}>let's play!</p>
                    </div>
                </Col>
            </Row>

            <div className="display-container">
                {/* Imagen de fondo */}
                <Row className='mb-2 p-2 flex j-center'>
                    <div style={{ position: 'relative', width: '80vw' }} className='ps-0'>
                        <img style={{ width: '80vw' }} className='br-5' src={`/imgs/${currentQuestion.img}`} />
                    </div>
                </Row>

                {/* Pregunta actual */}
                <Row className='mb-2 p-2 flex j-center'>
                    <Col style={{ borderLeft: '3.5px solid #1cf100', maxWidth: '80vw' }} className='ps-0 pe-0'>
                        <p className='txt-center txtWhite fs-18 mb-0'>{currentQuestion.pregunta}</p>
                    </Col>
                </Row>

                {/* Opciones de respuesta */}
                <Row className='p-2 flex j-center'>
                    <div style={{ position: 'relative', width: '80vw' }}>
                        {/* Botón Verdadero */}
                        <Button
                            className='noBorder'
                            disabled={selectedOption !== null}
                            onClick={() => setSelectedOption(0)}
                            style={{
                                height: '70px',
                                width: '80vw',
                                backgroundImage: 'linear-gradient(to right, #A3E635, #16A34A)',
                                opacity: showAnswers ? (currentQuestion.respuesta === currentQuestion.opciones[0] ? 1 : 0.5) : 1
                            }}
                        >
                            <div className='fw600' style={{ fontSize: '28px' }}>Verdadero</div>
                        </Button>

                        {/* Círculo entre las opciones */}
                        <div className="circle-or-container" style={{ position: 'absolute', top: '73px', left: '50%', transform: 'translateX(0%)', zIndex: 2 }}>
                            <span className="circle-or">o</span>
                        </div>

                        {/* Botón Falso */}
                        <Button
                            className='noBorder'
                            disabled={selectedOption !== null}
                            onClick={() => setSelectedOption(1)}
                            style={{
                                height: '70px',
                                width: '80vw',
                                backgroundImage: 'linear-gradient(to right, #EF4444, #DC2626)',
                                marginTop: '5px',
                                opacity: showAnswers ? (currentQuestion.respuesta === currentQuestion.opciones[1] ? 1 : 0.5) : 1
                            }}
                        >
                            <div className='fw600' style={{ fontSize: '28px' }}>Falso</div>
                        </Button>
                    </div>
                </Row>
            </div>
        </div>
    );
};

export default Display;
