import React, { useState, useEffect } from 'react';
import { Container, Row, Button, Modal, Spinner, Col } from 'react-bootstrap';
import { collection, onSnapshot, doc, updateDoc, increment, query, orderBy, getDocs } from 'firebase/firestore';
import { db } from '../../../firebase';
import { useNavigate } from 'react-router-dom';
import '../../../App.css';
import Ranking from './Ranking';
import moment from 'moment';

export default function Interaction1() {
    const [userInfo, setUserInfo] = useState({});
    const [interaction, setInteraction] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

    const [timeLeft, setTimeLeft] = useState(0);
    const [showResult, setShowResult] = useState(false);

    const [selectedOption, setSelectedOption] = useState(null);
    const [correctAnswer, setCorrectAnswer] = useState(false);
    const [userRank, setUserRank] = useState(null);  // Estado para la posición del usuario en el ranking
    const [userScore, setUserScore] = useState(0);  // Estado para el puntaje del usuario
    
    const navigate = useNavigate();

    // Monitorear los cambios en el estado de interacción en Firestore
    useEffect(() => {
        const uinfo = localStorage.getItem("userInfo");
        if (uinfo) {
            setUserInfo(JSON.parse(uinfo));
        }

        const gameStateDocRef = doc(db, 'gameState', 'current');

        const unsubscribe = onSnapshot(gameStateDocRef, (doc) => {
            if (doc.exists()) {
                const gameStateData = doc.data();

                if (gameStateData.showRanking) {
                    console.log("No hay preguntas")

                    navigate('/ranking');
                    return;
                }

                const currentDateTime = moment();
                let timeLeft1 = 10; // Tiempo por defecto
    
                // Obtén el índice actual desde localStorage
                const storedQuestionIndex = localStorage.getItem('currentQuestionIndex');
                const storedStartTime = localStorage.getItem('startTime');
    
                // Verifica si hay un índice almacenado
                if (storedQuestionIndex === null) {
                    // Si no hay índice almacenado, inicializa el valor
                    localStorage.setItem('currentQuestionIndex', gameStateData.currentQuestionIndex);
                    localStorage.setItem('startTime', moment().toISOString());
                    timeLeft1 = 10; // Tiempo inicial
                } else {
                    // Si hay un índice almacenado, verifica si coincide
                    if (storedQuestionIndex != gameStateData.currentQuestionIndex) {
                        // Si no coincide, actualiza el localStorage
                        localStorage.setItem('currentQuestionIndex', gameStateData.currentQuestionIndex);
                        localStorage.setItem('startTime', moment().toISOString());
                        timeLeft1 = 10; // Reinicia el temporizador a 10 segundos
                    } else {
                        // Si coincide, calcula el tiempo restante
                        const startTime = moment(storedStartTime);
                        const elapsed = currentDateTime.diff(startTime, 'seconds');
                        timeLeft1 = Math.max(10 - elapsed, 0); 
                    }
                }
                setTimeLeft(timeLeft1);

                if (gameStateData.currentQuestionIndex !== currentQuestionIndex && gameStateData.currentQuestionIndex <= (interaction.length)) {
                    setCurrentQuestionIndex(gameStateData.currentQuestionIndex);
                    setSelectedOption(null);
                    setCorrectAnswer(false);
                    setShowResult(false);
                    
                }
                console.log(timeLeft1)
                
                
            }
        });

        return () => unsubscribe(); // Cleanup listener cuando el componente se desmonte
    }, [currentQuestionIndex, interaction.length]);

    // Cargar las preguntas de la colección
    useEffect(() => {
        const unsubscribe = onSnapshot(collection(db, 'question_boolean'), (snapshot) => {
            const questionsData = snapshot.docs.map(doc => doc.data());
            setInteraction(questionsData);
        }, (error) => {
            console.error("Error al obtener preguntas: ", error);
        });

        return () => unsubscribe();
    }, []);

    // Obtener el ranking en tiempo real y calcular la posición del usuario
    useEffect(() => {
        const fetchRankingData = async () => {
            try {
                const q = query(
                    collection(db, 'user_responses'),
                    orderBy('score', 'desc')
                );
                const querySnapshot = await getDocs(q);

                const users = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                const userPosition = users.findIndex(user => user.email === userInfo.email) + 1;
                setUserRank(userPosition); // Guardar la posición del usuario en el ranking
            } catch (error) {
                console.error('Error al obtener los datos del ranking:', error);
            }
        };

        if (userInfo.email) {
            fetchRankingData();
        }
    }, [userScore, userInfo.email]); // Vuelve a calcular la posición cuando cambie el puntaje

    // Manejo del tiempo restante
    useEffect(() => {
        let timer;
        if (timeLeft > 0 && interaction.length > 0) {
            timer = setTimeout(() => setTimeLeft(prev => prev - 1), 1000);
        } else if (timeLeft === 0 && interaction.length > 0 && !showResult) {
            // Cuando el tiempo se acaba, mostrar el modal
            setShowResult(true);
        }
        return () => clearTimeout(timer);
    }, [timeLeft, selectedOption, interaction, currentQuestionIndex, showResult]);

    // Función para manejar la respuesta correcta y sumar puntos
    const handleCorrectAnswer = async () => {
        try {
            const totalQuestions = interaction.length; // Cantidad total de preguntas en la interacción
            const maxPoints = 1000 / totalQuestions;  // Distribuir 1000 puntos entre todas las preguntas
            const timeFactor = timeLeft / 20; // Factor basado en el tiempo restante
            const pointsToAdd = maxPoints * timeFactor;  // Puntos que se reducen a medida que pasa el tiempo
            const userRef = doc(db, 'user_responses', userInfo.email);
            await updateDoc(userRef, {
                score: increment(pointsToAdd)
            });
            setUserScore(prev => prev + pointsToAdd);
        } catch (error) {
            console.error("Error al actualizar el puntaje:", error);
        }
    };

    // Función para manejar la selección de la respuesta
    const handleAnswer = (index) => {
        if (!showResult && interaction.length > 0) {
            setSelectedOption(index);
            const currentQuestion = interaction[currentQuestionIndex];
            const selectedText = currentQuestion.opciones[index];
            const isCorrect = selectedText === currentQuestion.respuesta;
            setCorrectAnswer(isCorrect);

            if (isCorrect) {
                handleCorrectAnswer();  // Actualiza los puntos si es correcto
            }
        }
    };

    // Manejo de errores o estados incompletos
    if (!userInfo || !userInfo.firstName || !userInfo.lastName) {
        return <div>Error: Datos de usuario incompletos o indefinidos</div>;
    }

    if (interaction.length === 0) {
        return <div className='flex j-center content a-center'>
            <Spinner animation="border" role="status" variant="" style={{ color: "#1cf100" }}>
                <span className="visually-hidden"></span>
            </Spinner>
        </div>;
    }

    const currentQuestion = interaction[currentQuestionIndex];

    return (
        <div className="questions-page content">
            <Row className='pt-4'>
                <Col className='flex j-center'>
                    <div className='fitContentWidth'>
                        <img style={{ height: '35px' }} src="/imgs/media_day_logo.png" />
                        <p className='txtWhite mt-1 fs-18 me-1 mb-0' style={{ textAlign: 'right' }}>let's play!</p>
                    </div>
                </Col>
            </Row>
            {currentQuestion ? <Container className="text-center questions-container">
                <Row className='mb-2 p-2 flex j-center'>
                    <div style={{ position: 'relative', width: '80vw' }} className='ps-0'>
                        <img style={{ width: '80vw' }} className='br-5' src={`/imgs/${currentQuestion.img}`} />
                        <div className='fitContentWidth' style={{ position: 'absolute', bottom: 5, right: 3 }}>
                            <img style={{ width: '50px' }} className='br-5' src="/imgs/timer1.png" />
                        </div>
                        <div className='fw800 txt-center' style={{ position: 'absolute', bottom: 9, right: 10.5, width: '35px', height: '35px', borderRadius: '50%', backgroundColor: '#1cf100', fontSize: '25px' }}>
                            {timeLeft}
                        </div>
                    </div>
                </Row>

                <Row className='mb-2 p-2 flex j-center'>
                    <Col style={{ borderLeft: '3.5px solid #1cf100', maxWidth: '80vw' }} className='ps-0 pe-0'>
                        <p className='txt-center txtWhite fs-18 mb-0'>{currentQuestion && currentQuestion.pregunta}</p>
                    </Col>
                </Row>
                <Row className='p-2 flex j-center'>

                    <Button disabled={selectedOption !== null} onClick={() => handleAnswer(0)} className='gradient-green noBorder' style={{ height: '70px', marginBottom: '-7px', width: '80vw', opacity: selectedOption !== null && selectedOption !== 0 ? 0.5 : 1 }}>
                        <div className='fw600' style={{ fontSize: '28px' }}>
                            Verdadero
                        </div>
                    </Button>
                    <div className="circle-or-container">
                        <span className="circle-or">o</span>
                    </div>
                    <Button disabled={selectedOption !== null} onClick={() => handleAnswer(1)} className='gradient-red noBorder' style={{ height: '70px', width: '80vw', opacity: selectedOption !== null && selectedOption !== 1 ? 0.5 : 1 }}>
                        <div className='fw600' style={{ fontSize: '28px' }}>
                            Falso
                        </div>
                    </Button>
                </Row>
                <Modal
    show={showResult}
    centered
    onHide={() => setShowResult(false)}
    backdrop="static"
>
    <Modal.Body
        style={{
            backgroundColor: '#0d0d0d', // Fondo negro intenso
            color: '#ffffff', // Texto en blanco para todo el contenido
            textAlign: 'center',
            padding: '20px',
            borderRadius: '2px',
        }}
    >
        <h2
            style={{
                fontSize: '28px',
                fontWeight: 'bold',
                marginBottom: '15px',
                color: correctAnswer ? '#1cf100' : '#ff0000', // Verde si es correcto, rojo si es incorrecto
            }}
        >
            {correctAnswer ? "¡Correcto!" : "¡Incorrecto!"}
        </h2>
        <p
            style={{
                color: '#ffffff', // Texto blanco para el contenido
            }}
        >
            Puntuación actual: {userScore.toFixed()} Puntos
        </p>
        <p
            style={{
                color: '#ffffff', // Texto blanco para el contenido
            }}
        >
            Tu posición en el ranking: #{userRank}
        </p>
    </Modal.Body>
</Modal>



            </Container> :
                <p className='txtWhite mt-4'>
                    Gracias por jugar!
                </p>
            }
        </div>
    );
}
