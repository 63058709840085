import React, { useState, useEffect } from 'react';
import { doc, setDoc, collection, getDocs } from "firebase/firestore";
import { db } from '../../../firebase';
import ProgressBar from 'react-bootstrap/ProgressBar';
import '../../../App.css';
import { Col, Row } from 'react-bootstrap';

const Interaction4 = () => {
    const [userInfo, setUserInfo] = useState({});
    const [clickCount, setClickCount] = useState(0);
    const [power, setPower] = useState(0);
    const [startTime, setStartTime] = useState(null);
    const [timeTaken, setTimeTaken] = useState(null);
    const [isInteractionActive, setIsInteractionActive] = useState(false);
    const [assignedCharacter, setAssignedCharacter] = useState(null);  // Estado para almacenar el personaje asignado
    const [position, setPosition] = useState(1);
    const [totalParticipants, setTotalParticipants] = useState(1);
    const [currentElapsedTime, setCurrentElapsedTime] = useState(0);
    const [timerId, setTimerId] = useState(null);
    const [countdown, setCountdown] = useState(3);
    const [isTimeVisible, setIsTimeVisible] = useState(true);
    const [isButtonActive, setIsButtonActive] = useState(false);
    const characters = [
        "characters/jugo.gif",
        "characters/jugoUno.gif",
        "characters/jugoDos.gif",
        "characters/jugoTres.gif",
        "characters/jugoCuatro.gif",
        "characters/jugoCinco.gif",
        "characters/jugoSeis.gif",
    ]

    // Formato de tiempo HH:MM:SS (con dos dígitos en los segundos)
    const formatTime = (seconds) => {
        const hours = String(Math.floor(seconds / 3600)).padStart(2, '0');
        const minutes = String(Math.floor((seconds % 3600) / 60)).padStart(2, '0');
        const secs = String(Math.floor(seconds % 60)).padStart(2, '0');
        return `${hours}:${minutes}:${secs}`;
    };

    useEffect(() => {
        const uinfo = localStorage.getItem("userInfo");
        if (uinfo) {
            const parsedUserInfo = JSON.parse(uinfo);
            setUserInfo(parsedUserInfo);

            // Seleccionar un personaje aleatorio
            const randomIndex = Math.floor(Math.random() * characters.length);
            const selectedCharacter = characters[randomIndex];
            setAssignedCharacter(selectedCharacter);  // Asignar el personaje seleccionado
        }

        const decreasePower = () => {
            setPower((prevPower) => {
                if (prevPower > 0) {
                    return prevPower - 4;
                }
                return 0;
            });
        };

        const intervalId = setInterval(decreasePower, 100);

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        // Obtener el número total de participantes cuando se carga el componente
        const fetchParticipants = async () => {
            try {
                const timesSnapshot = await getDocs(collection(db, 'user_times'));
                setTotalParticipants(timesSnapshot.docs.length); // Guardar el número de jugadores
            } catch (error) {
                console.error("Error al obtener los participantes: ", error);
            }
        };

        fetchParticipants();
    }, []);

    useEffect(() => {
        // Hacer el contador regresivo
        if (countdown > 0) {
            const countdownTimer = setTimeout(() => setCountdown(countdown - 1), 1000);
            return () => clearTimeout(countdownTimer);
        } else {
            setIsInteractionActive(true); // Habilitar la interacción cuando el contador llega a 0
        }
    }, [countdown]);

    useEffect(() => {
        if (clickCount > 0) {
            const timer = setInterval(() => {
                setCurrentElapsedTime((prevTime) => prevTime + 1);
            }, 1000);
            setTimerId(timer);
            return () => clearInterval(timer); // Limpia el intervalo cuando se desmonta
        }
    }, [clickCount]);

    // Recalcular la posición cada vez que el usuario hace clic
    const recalculatePosition = async (elapsedTime) => {
        try {
            const timesSnapshot = await getDocs(collection(db, 'user_times'));
            const timesArray = timesSnapshot.docs.map(doc => doc.data().timeTaken);

            if (timesArray.length > 0) {
                const sortedTimes = timesArray.sort((a, b) => a - b);
                let userPosition = sortedTimes.indexOf(elapsedTime) + 1;

                if (userPosition < 1) {
                    userPosition = 1;
                }
                setPosition(userPosition);
            }
            setTotalParticipants(timesArray.length || 1); // Asegurarse de que siempre haya al menos 1 participante
        } catch (error) {
            console.error("Error al recalcular la posición: ", error);
        }
    }; 

    const handleButtonClick = async () => {
        setIsButtonActive(true);
        setTimeout(() => setIsButtonActive(false), 200); // Desactivar el efecto después de 200 ms

        if (!userInfo) {
            console.error("No se encontró la información del usuario.");
            return;
        }

        const userRef = doc(db, 'user_times', `${userInfo.firstName}_${userInfo.lastName}_${userInfo.nickname}`);

        if (clickCount === 0) {
            setStartTime(new Date());

            const timer = setInterval(() => {
                setCurrentElapsedTime((prevTime) => prevTime + 1);
            }, 1000);
            setTimerId(timer);
        }

        const newClickCount = clickCount + 1;
        setClickCount(newClickCount);
        setPower((prevPower) => Math.min(prevPower + 30, 100));

        const currentTime = new Date();
        const elapsedTime = (currentTime - startTime) / 1000;

        try {
            await setDoc(userRef, {
                timeTaken: elapsedTime,
                firstName: userInfo.firstName,
                lastName: userInfo.lastName,
            }, { merge: true });

            recalculatePosition(elapsedTime);
        } catch (error) {
            console.error("Error al guardar el tiempo en la base de datos: ", error);
        }

        if (newClickCount === 60) {
            const endTime = new Date();
            const totalTime = (endTime - startTime) / 1000;
            setTimeTaken(totalTime);

            clearInterval(timerId);
            setTimerId(null);

            setIsTimeVisible(false);

            try {
                await setDoc(userRef, {
                    timeTaken: totalTime,
                    completedAt: new Date().toISOString(),
                }, { merge: true });

                console.log(`Tiempo guardado: ${totalTime} segundos`);
            } catch (error) {
                console.error("Error al guardar el tiempo final en la base de datos: ", error);
            }
        }
    };

    if (!userInfo || !userInfo.firstName || !userInfo.lastName) {
        return <div>Error: No se encontró la información del usuario.</div>;
    }

    return (
        <div className="content">
            {/* Parte superior con el logo en fondo negro */}
            <Row className="pt-4" style={styles.topSection}>
                <Col className="flex j-center">
                    <div className="fitContentWidth">
                        <img style={{ height: '35px' }} src="/imgs/media_day_logo.png" />
                        <p className="txtWhite mt-1 fs-18 me-1 mb-0" style={{ textAlign: 'right' }}>let's play!</p>
                    </div>
                </Col>
            </Row>

            {/* Parte media blanca fija */}
            <div style={styles.middleSection}>
                <div style={styles.container}>
                    <div style={styles.infoContainer}>
                        {/* Mostrar el temporizador solo cuando la cuenta regresiva haya terminado */}
                        {isTimeVisible && countdown <= 0 && (
                            <div style={styles.leftContainer}>
                                <h3 style={styles.timer}>{formatTime(currentElapsedTime)}</h3>
                            </div>
                        )}
                    </div>

                    {!isInteractionActive && (
                        <div style={styles.overlay}>
                            <h2 style={styles.countdown}>{countdown > 0 ? countdown : 'GO!'}</h2>
                        </div>
                    )}

                    {assignedCharacter && isInteractionActive && (
                        <div style={styles.characterContainer}>
                            <img
                                src={assignedCharacter}
                                alt={assignedCharacter}
                                style={styles.characterImage}
                            />
                        </div>
                    )}

                    {timeTaken ? (
                        <div>
                            <h2 style={styles.completedTitle}>¡Completado!</h2>
                            <div style={styles.finalResultContainer}>
                                <p>Tiempo total: {formatTime(timeTaken)}</p>
                              
                            </div>
                        </div>
                    ) : (
                        isInteractionActive && (
                            <div>
                                <button
                                    onClick={handleButtonClick}
                                    disabled={clickCount >= 60}
                                    style={{
                                        ...styles.button,
                                        ...(isButtonActive ? styles.buttonActive : {})
                                    }}
                                >
                                    Correr!
                                </button>

                                <div style={styles.progressBarContainer}>
                                    <ProgressBar now={power} style={styles.progressBar} />
                                </div>
                            </div>
                        )
                    )}
                </div>
            </div>

            {/* Parte inferior con el logo en fondo negro */}
            <Row className="pt-4" style={styles.bottomSection}>
                <Col className="flex j-center">
                    <div className="fitContentWidth">
                        <p className="txtWhite mt-1 fs-18 me-1 mb-0" style={{ textAlign: 'right' }}></p>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

// Estilos
const styles = {
    topSection: {
        backgroundColor: 'black',
        height: '10dvh',
    },
    middleSection: {
        backgroundColor: 'white',
        height: '80dvh',
        overflowY: 'auto',
        marginTop: '5dvh',
        zIndex: 1,
    },
    bottomSection: {
        backgroundColor: 'black',
        height: '10dvh',
    },
    container: {
        textAlign: 'center',
        marginTop: '5dvh',
        padding: '0 2vw',
    },
    infoContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '80%',
        margin: '0 auto',
    },
    leftContainer: {
        flex: 1,
        textAlign: 'left',
    },
    timer: {
        fontSize: '4vw',
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
    },
    countdown: {
        fontSize: '30vw',
        opacity: 0.5,
        transition: 'opacity 3s ease',
        color: 'blue',
        fontWeight: 'bold',
    },
    characterContainer: {
        marginBottom: '0dvh',
    },
    characterImage: {
        width: '200px',  // Cambiamos el tamaño a 200px de ancho
        height: 'auto',  // Mantener la proporción de la imagen
        marginTop: '75px',
    },
    button: {
        width: '60vw',
        height: '10dvh',
        borderRadius: '20px',
        fontSize: '10vw',
        backgroundColor: '#FFD700',
        fontWeight: 'bold',
        color: 'white',
        border: 'none',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        transition: 'transform 0.2s ease',
        marginTop: '10dvh',
    },
    buttonActive: {
        transform: 'scale(0.95)',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.5)',
        transition: 'transform 0.2s ease, box-shadow 0.5s ease',
    },
    progressBarContainer: {
        width: '60vw',
        margin: 'auto',
        marginTop: '5dvh',
    },
    progressBar: {
        height: '5dvh',
        borderRadius: '20px',
    },
    completedTitle: {
        marginTop: '5dvh',
        fontSize: '5vw',
        color: 'black',
    },
    finalResultContainer: {
        marginTop: '5dvh',
        fontSize: '4vw',
        textAlign: 'center',
    },
};

export default Interaction4;