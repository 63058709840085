import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Row } from "react-bootstrap";
import Interaction1 from "./components/ui/interactions/Interaction1";
import Interaction2 from "./components/ui/interactions/Interaction2";
import Interaction3 from "./components/ui/interactions/Interaction3";
import Interaction4 from "./components/ui/interactions/Interaction4";
import Interaction5 from "./components/ui/interactions/Interaction5";
import Interaction6 from "./components/ui/interactions/Interaction6";
import Interaction7 from "./components/ui/interactions/Interaction7.js"; // Asegúrate de que esta ruta sea correcta.
import Ranking from "./components/ui/interactions/Ranking";
import Login from "./components/ui/login/Login";
import JoinGame from "./components/ui/registration/JoinGame";
import GameRoom from "./components/ui/registration/GameRoom";
import Display from "./components/ui/Display";
import Host from "./components/ui/Host";
import InteractionWatcher from './components/InteractionWatcher';
import QuestionScreen from './components/ui/interactions/QuestionScreen'; // Nueva importación
import { collection, onSnapshot } from "firebase/firestore";
import { db } from './firebase';
import './App.css';
import Home from "./components/ui/home/Home";
import Menu from "./components/ui/menu/Menu";
import WaitingRoom from "./components/ui/login/WaitingRoom";
import CharacterComponent from "./components/assets/characters.js";
import Survey from "./components/ui/interactions/Survey.js";

const bootstrapCSS = (
    <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css"
        integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC"
        crossOrigin="anonymous"
    />
);

export function App() {


    const [userInfo, setUserInfo] = useState(null);
    const [gameActive, setGameActive] = useState(false);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [totalQuestions, setTotalQuestions] = useState(0);

    useEffect(() => {
        document.title = "Media Day"
        const unsubscribe = onSnapshot(collection(db, 'questions_8888'), (snapshot) => {
            const questionsCount = snapshot.docs.length;
            setTotalQuestions(questionsCount);
            console.log("Total de preguntas:", questionsCount);
        });

        return () => unsubscribe();
    }, []);

    const handleLogin = (info) => {
        setUserInfo(info);
    };

    return (
        <div>
            <Router>
                {bootstrapCSS}
                <Row className="m-0">
                    <Routes>
                        <Route path="/" element={<InteractionWatcher />} />
                        <Route path="/inicio" element={
                            <Home />
                        } />
                        <Route path="/menu" element={
                            <>
                                <InteractionWatcher />
                                <Menu />
                            </>
                        } />
                        <Route path="/waiting" element={
                            <>
                                <InteractionWatcher />
                                <WaitingRoom userInfo={userInfo} gameActive={gameActive} setGameActive={setGameActive} currentQuestionIndex={currentQuestionIndex} setCurrentQuestionIndex={setCurrentQuestionIndex} />
                            </>
                        } />
                        <Route path="/interaction1" element={
                            <>
                                <InteractionWatcher />
                                <Interaction1 userInfo={userInfo} gameActive={gameActive} setGameActive={setGameActive} currentQuestionIndex={currentQuestionIndex} setCurrentQuestionIndex={setCurrentQuestionIndex} />
                            </>
                        } />
                        <Route path="/interaction2" element={
                            <>
                                <InteractionWatcher />
                                <Interaction2 userInfo={userInfo} gameActive={gameActive} setGameActive={setGameActive} />
                            </>
                        } />
                        <Route path="/interaction3" element={
                            <>
                                <InteractionWatcher />
                                <Interaction3 userInfo={userInfo} gameActive={gameActive} setGameActive={setGameActive} currentQuestionIndex={currentQuestionIndex} setCurrentQuestionIndex={setCurrentQuestionIndex} />
                            </>
                        } />
                        <Route path="/interaction4" element={
                            <>
                                {<InteractionWatcher />}
                                <Interaction4 userInfo={userInfo} />

                            </>
                        } />
                        <Route path="/interaction5" element={
                            <>
                                <InteractionWatcher />
                                <Interaction5 userInfo={userInfo} />
                            </>} />

                        <Route path="/interaction6" element={
                            <>
                                <InteractionWatcher />
                                <Interaction6 userInfo={userInfo} />
                            </>
                        } />
                        <Route path="/interaction7" element={
                            <>
                                <InteractionWatcher />
                                <Interaction7 userInfo={userInfo} />
                            </>} />
                        <Route path="/ranking" element={
                            <>
                                <InteractionWatcher />
                                <Ranking />
                            </>
                        } />

                        <Route path="/login" element={<Login onLogin={handleLogin} setGameActive={setGameActive} />} />
                        <Route path="/host" element={<Host setGameActive={setGameActive} currentQuestionIndex={currentQuestionIndex} setCurrentQuestionIndex={setCurrentQuestionIndex} totalQuestions={totalQuestions} />} />
                        <Route path="/join" element={<JoinGame />} />
                        <Route path="/game" element={<GameRoom gameCode="current" />} />
                        <Route path="/display" element={<Display />} />

                        <Route path="/host/chat" element={<Interaction6 isHost={true} />} />

                        {/* Nueva ruta para la pantalla de preguntas */}
                        <Route path="/question/:category" element={<QuestionScreen />} />


                        <Route path="/characters" element={<CharacterComponent />} />

                        <Route path="/survey" element={<>
                            <InteractionWatcher />
                            <Survey />
                        </>
                        } />


                        <Route path="/encuesta" element={<Survey/>} />


                    </Routes>
                </Row>
            </Router>
        </div>
    );
}
