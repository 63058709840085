import { addDoc, collection } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { Col, Row, Form, Button } from 'react-bootstrap'
import { db } from '../../../firebase'

function Survey() {

    const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem('userInfo')))
    const [isUserRegistered, setIsUserRegistered] = useState(true)

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [position, setPosition] = useState('');

    const [currentQuestion, setCurrentQuestion] = useState(0)

    useEffect(() => {
        const userInfo = localStorage.getItem('userInfo');

        if (!userInfo) {
            setIsUserRegistered(false)
            setCurrentQuestion(-1)
        }else{
            setCurrentQuestion(0)
        }

    }, [])

    const optionsQuestion1 = ['Agencia', 'Redes', 'Invitación', 'Soy sponsor', 'Directo', 'Referido', 'Mail', 'Prensa', 'Otro']
    const optionsQuestion3 = ['Excelente', 'Muy bueno', 'Bueno', 'Regular', 'Malo']
    const optionsQuestion4 = ['Bloque 1: Fuente Creeme', 'Bloque 2: Shippeandonos', 'Momento Oro: Mercado Ads', 'Rueda de casos', 'Bloque 3: Funtertaiment', 'Bloque 4: Mood Artificial', 'Bloque 5: Content non stop', 'Bloque 6: Era joda y quedó']
    const optionsQuestion7 = ['Altamente probable', 'Probable', 'Poco probable', 'Nada probable']
    const optionsQuestion8 = ['Puntualidad', 'Locación', 'Organización del evento', 'Aspectos técnicos: sonido, imagen, conexión wifi', 'Contenido de las charlas', 'Espacios de networking']

    const [knownCauses, setKnownCauses] = useState([])
    const [generalCalification, setGeneralCalification] = useState(null)
    const [recommendRate, setRecommendRate] = useState("")
    const [sastifactionBlock, setSastificationBlock] = useState(optionsQuestion4.map(() => null))
    const [bestSpeakers, setBestSpeakers] = useState(optionsQuestion4.map(() => null))
    const [loveMoment, setLoveMoment] = useState("")
    const [futureAssistance, setFutureAssistance] = useState("")
    const [technicalSatisfaction, setTechnicalSatisfaction] = useState(optionsQuestion8.map(() => null))
    const [finalComment, setFinalComment] = useState("")

    const handleQuestion1 = (e) => {
        const { value, checked } = e.target;

        if (checked) {
            // Agregar el género seleccionado al array si está marcado
            setKnownCauses(prev => [...prev, value]);
        } else {
            // Remover el género del array si está desmarcado
            setKnownCauses(prev => prev.filter(gender => gender !== value));
        }
    };

    const handleQuestion4 = (text, i) => {
        let arr = [...sastifactionBlock]
        arr[i] = text
        setSastificationBlock(arr)
    }

    const handleQuestion5 = (text, i) => {
        let ar = [...bestSpeakers]
        ar[i] = text
        setBestSpeakers(ar)
    }

    const handleQuestion8 = (text, i) => {
        let arr = [...technicalSatisfaction]
        arr[i] = text
        setTechnicalSatisfaction(arr)
    }

    useEffect(() => {
        console.log("Speakers")
        console.log(bestSpeakers)
    }, [bestSpeakers])

    const nextQuestion = () => {
        setCurrentQuestion(currentQuestion + 1)
    }

    const saveForm = async () => {
        console.log("")
        let userData = userInfo;
        if (!isUserRegistered) {
            userData = {
                firstName,
                lastName,
                email,
                company,
                position
            }

        }
        let body = {
            user: userData,
            survey: [
                { question: '¿Cómo te enteraste del Media Day 2024?', answer: knownCauses },
                { question: '¿Cómo calificarías el evento en general?', answer: generalCalification },
                { question: '¿Cuán probable es que recomiendes el MEDIA DAY  a un colega?', answer: recommendRate },
                { question: 'Nivel de sastifacción con respecto a la información y contenido de los bloques', answer: sastifactionBlock.map((r, i) => ({ param: optionsQuestion4[i], value: r })) },
                { question: 'Speakers/casos que te parecieron más destacados en cada bloque', answer: bestSpeakers.map((r, i) => ({ param: optionsQuestion4[i], value: r })) },
                { question: '¿Qué fue lo que más te gusto del evento?', answer: loveMoment },
                { question: '¿Cuán probable es que asistas nuevamente al evento en una futura edición?', answer: futureAssistance },
                { question: '¿Cuál es tu nivel de sastifacción respecto a los siguientes aspectos del Media Day 2024?', answer: technicalSatisfaction.map((r, i) => ({ param: optionsQuestion8[i], value: r })) },
                { question: 'Comentarios y sugerencias', answer: finalComment },
            ]
        }

        console.log(body)

        try {
            // Crea un documento en la colección 'survey_answers'
            await addDoc(collection(db, 'survey_answers'), body);
            setCurrentQuestion(currentQuestion + 1)
        } catch (error) {
            console.error('Error al enviar las respuestas:', error);
        }


    }

    const [rating, setRating] = useState(5); // El valor inicial puede ser 5 o cualquier número dentro del rango

    // Manejar el cambio en el valor del slider
    const handleRangeChange = (e) => {
        console.log(e.target.value)
        setRating(e.target.value); // Actualiza el estado con el nuevo valor del range
    };

    const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

    return (
        <div className='content txtWhite surveyContent pb-3'>
            <Row className='pt-3 ps-2 pe-2 mb-2'>
                <Col className='flex a-center'>
                    <img style={{ height: '30px' }} src={'/imgs/caam.png'} />
                </Col>
                <Col className='endRow a-center'>
                    <img style={{ height: '50px' }} src={'/imgs/md_sticker.png'} />
                </Col>
            </Row>
            <div style={{ minHeight: 'calc(100dvh - 100px)', flexDirection: 'column' }} className='flex a-center j-center'>
                {currentQuestion == -1 && !isUserRegistered ?
                    <><Row>
                        <Col className='ps-4 pe-4'>
                            <p className='txt-center fs-22 fw600'>Encuesta Media Day</p>
                            <p className=' fs-14'>Agradecemos su participación en el Media Day de la CAAM 2024 . Le solicitamos completar los siguientes puntos a fin de aprender sobre su opinión sobre el evento . El total de la encuesta lleva entre 1 y 3 minutos</p>
                        </Col>
                    </Row>
                        <Row className='ps-4 pe-4'>
                            {/* nombre, apelliod, mail, empresa y cargo */}
                            <Form.Group controlId="formFirstName" className='mt-2'>
                                <Form.Label className="mb-2 ps-2" style={{ borderLeft: '3.5px solid #1cf100' }}>Nombre</Form.Label>
                                <Form.Control
                                    type="text"
                                    /* placeholder="Introduce tu nombre" */
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    required
                                    className=" noBorder"
                                />
                            </Form.Group>

                            <Form.Group controlId="formLastName" className='mt-2'>
                                <Form.Label className="mb-2 ps-2" style={{ borderLeft: '3.5px solid #1cf100' }}>Apellido</Form.Label>
                                <Form.Control
                                    type="text"
                                    /* placeholder="Introduce tu apellido" */
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                    required
                                    className=" noBorder"
                                />
                            </Form.Group>
                            <Form.Group controlId="formEmail" className='mt-2'>
                                <Form.Label className="mb-2 ps-2" style={{ borderLeft: '3.5px solid #1cf100' }}>Correo Electrónico</Form.Label>
                                <Form.Control
                                    type="email"
                                    /* placeholder="Introduce tu correo electrónico" */
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    className="noBorder"
                                />
                            </Form.Group>

                            <Form.Group controlId="formCompany" className='mt-2'>
                                <Form.Label className="mb-2 ps-2" style={{ borderLeft: '3.5px solid #1cf100' }}>Empresa</Form.Label>
                                <Form.Control
                                    type="text"
                                    /* placeholder="Introduce la empresa en la que trabajas" */
                                    value={company}
                                    onChange={(e) => setCompany(e.target.value)}
                                    className="noBorder"
                                />
                            </Form.Group>

                            <Form.Group controlId="formPosition" className='mt-2'>
                                <Form.Label className="mb-2 ps-2" style={{ borderLeft: '3.5px solid #1cf100' }}>Cargo</Form.Label>
                                <Form.Control
                                    type="text"
                                    /* placeholder="Introduce tu cargo" */
                                    value={position}
                                    onChange={(e) => setPosition(e.target.value)}
                                    className="noBorder"
                                />
                            </Form.Group>
                        </Row>
                    </>
                    : currentQuestion == 0 || currentQuestion==-1 ?
                        <>
                            {isUserRegistered ? <Row>
                                <Col className='ps-4 pe-4'>
                                    <p className='txt-center fs-22 fw600'>Encuesta Media Day</p>
                                    <p className=' fs-14'>Agradecemos su participación en el Media Day de la CAAM 2024 . Le solicitamos completar los siguientes puntos a fin de aprender sobre su opinión sobre el evento . El total de la encuesta lleva entre 1 y 3 minutos</p>
                                </Col>
                            </Row> : ''}
                            <Row className='ps-4 pe-4'>
                                <Form.Group controlId="knownCauses" className='mt-2'>
                                    <Form.Label className="mb-2 ps-2" style={{ borderLeft: '3.5px solid #1cf100' }}>¿Cómo te enteraste del Media Day 2024?</Form.Label>
                                    {/* Checkbox para Masculino */}
                                    {optionsQuestion1.map((r, i) => (
                                        <Form.Check
                                            type="checkbox"
                                            label={r}
                                            value={r}
                                            checked={knownCauses.includes(r)}
                                            onChange={handleQuestion1}
                                            className="noBorder surveyCheck"
                                            key={i}
                                            id={`knownCause-${r}`}
                                        />
                                    ))}

                                </Form.Group>
                            </Row></>
                        : currentQuestion == 1 ?
                            <Row className='ps-4 pe-4'>
                                <Form.Group controlId="recommendRate" className='mt-2 rangeContainer'>
                                    <Form.Label className="mb-2 ps-2" style={{ borderLeft: '3.5px solid #1cf100' }}>¿Cuán probable es que recomiendes el MEDIA DAY  a un colega , en una escala del 1 a 10 siendo 1 "nada probable" y 10 "altamente probable"?</Form.Label>
                                    {numbers.map((r, i) => (
                                        <Form.Check
                                            type="radio"
                                            value={r}
                                            label={r}
                                            onChange={() => setRecommendRate(r)}
                                            checked={r == recommendRate}
                                            className="surveyCheck noBorder"
                                            placeholder='Escribe aquí...'
                                            key={i}
                                            id={`recommendRate-${r}`}
                                        />
                                    ))}
                                </Form.Group>
                            </Row>
                            :
                            currentQuestion == 2 ?
                                <Row className='ps-4 pe-4'>
                                    <Form.Group controlId="generalCalification" className='mt-2'>
                                        <Form.Label className="mb-2 ps-2" style={{ borderLeft: '3.5px solid #1cf100' }}>¿Cómo calificarías el evento en general?</Form.Label>

                                        {optionsQuestion3.map((r, i) => (
                                            <Form.Check
                                                type="checkbox"
                                                label={r}
                                                value={r}
                                                checked={r == generalCalification}
                                                onChange={(e) => setGeneralCalification(e.target.value)}
                                                className="noBorder surveyCheck"
                                                key={i}
                                                id={r}
                                            />
                                        ))}
                                    </Form.Group>
                                </Row>
                                :
                                currentQuestion == 3 ?
                                    <Row className='ps-4 pe-4'>
                                        <Form.Group controlId="recommendRate" className='mt-2'>
                                            <p>Pensando en los diferentes bloques de CONTENIDO , te pedimos que indiques tu nivel de sastifacción con respecto a la información y contenido , siendo 1 muy poco sastifecho y 4 muy sastifecho</p>
                                            {optionsQuestion4.map((r, i) => (<>
                                                <Form.Label className="mb-1 ps-2 mt-3" style={{ borderLeft: '3.5px solid #1cf100' }}>{r}</Form.Label>

                                                <div className='mb-2'>
                                                    {numbers.map((rj, j) => j <= 3 && (
                                                        <Form.Check
                                                            type="radio"
                                                            label={rj}
                                                            value={rj}
                                                            checked={rj == sastifactionBlock[i]}
                                                            onChange={() => handleQuestion4(rj, i)}
                                                            className="noBorder surveyCheck me-3 mb-0"
                                                            key={j}
                                                            id={`${i}-${rj}`}
                                                        />
                                                    ))}</div></>))
                                            }
                                        </Form.Group>
                                    </Row>
                                    :
                                    currentQuestion == 4 ?
                                        <Row className='ps-4 pe-4'>
                                            <Form.Group controlId="bestSpeakers" className='mt-2'>
                                                <p>Mencioná los speakers/casos que te parecieron más destacados en cada bloque</p>


                                                {optionsQuestion4.map((r, i) => (<>
                                                    <Form.Label className="mb-2 ps-2" style={{ borderLeft: '3.5px solid #1cf100' }}>{r}</Form.Label>

                                                    <Form.Control
                                                        type="text"
                                                        value={bestSpeakers[i] == null ? '' : bestSpeakers[i]}
                                                        onChange={(e) => handleQuestion5(e.target.value, i)}
                                                        required
                                                        className="mb-3 noBorder"
                                                        placeholder='Escribe aquí...'
                                                    /></>))}

                                            </Form.Group>
                                        </Row>
                                        :
                                        currentQuestion == 5 ?
                                            <Row className='ps-4 pe-4'>
                                                <Form.Group controlId="loveMoment" className='mt-2'>

                                                    <Form.Label style={{ borderLeft: '3.5px solid #1cf100' }} className="mb-2 ps-2">¿Qué fue lo que más te gusto del evento?</Form.Label>

                                                    <Form.Control
                                                        type="text"
                                                        value={loveMoment}
                                                        onChange={(e) => setLoveMoment(e.target.value)}
                                                        required
                                                        className=" noBorder"
                                                        placeholder='Escribe aquí...'
                                                    />

                                                </Form.Group></Row>
                                            :
                                            currentQuestion == 6 ?
                                                <Row className='ps-4 pe-4'>
                                                    <Form.Group controlId="futureAssistance" className='mt-2'>
                                                        <Form.Label style={{ borderLeft: '3.5px solid #1cf100' }} className="mb-2 ps-2">¿Cuán probable es que asistas nuevamente al evento en una futura edición?</Form.Label>

                                                        {optionsQuestion7.map((r, i) => (
                                                            <Form.Check
                                                                type="checkbox"
                                                                label={r}
                                                                value={r}
                                                                checked={r == futureAssistance}
                                                                onChange={(e) => setFutureAssistance(e.target.value)}
                                                                className="noBorder surveyCheck"
                                                                key={i}
                                                                id={r}
                                                            />
                                                        ))}
                                                    </Form.Group></Row>
                                                :
                                                currentQuestion == 7 ?
                                                    <Row className='ps-4 pe-4'>
                                                        <Form.Group controlId="technical" className='mt-2'>

                                                            <p>¿Cuál es tu nivel de sastifacción respecto a los siguientes aspectos del Media Day 2024? En una escala del 1 al 5 , siendo 1 "Totalmente Insastifecho" y 5 "Totalmente Satisfecho"</p>

                                                            {optionsQuestion8.map((r, i) => (<>
                                                                <Form.Label style={{ borderLeft: '3.5px solid #1cf100' }} className="ps-2 mb-1">{r}</Form.Label>
                                                                <div className='mb-2'>
                                                                    {numbers.map((rj, j) => j <= 4 && (
                                                                        <Form.Check
                                                                            type="radio"
                                                                            label={rj}
                                                                            value={rj}
                                                                            checked={rj == technicalSatisfaction[i]}
                                                                            onChange={() => handleQuestion8(rj, i)}
                                                                            className="noBorder surveyCheck me-3 mb-0"
                                                                            key={j}
                                                                            id={`${i}-${rj}`}
                                                                        />
                                                                    ))}
                                                                </div>
                                                            </>))}

                                                        </Form.Group></Row>
                                                    :
                                                    currentQuestion == 8 ?
                                                        <Row className='ps-4 pe-4'>
                                                            <Form.Group controlId="finalComment" className='mt-2'>

                                                                <Form.Label style={{ borderLeft: '3.5px solid #1cf100' }} className="ps-2 mb-2">Dejanos por favor tus comentarios y sugerencias</Form.Label>

                                                                <Form.Control
                                                                    type="text"
                                                                    value={finalComment}
                                                                    onChange={(e) => setFinalComment(e.target.value)}
                                                                    required
                                                                    className=" noBorder"
                                                                    placeholder='Escribe aquí...'
                                                                />

                                                            </Form.Group></Row>
                                                        :
                                                        <><div className='flex j-center' style={{ flexDirection: 'column', height: 'calc(82dvh - 100px)' }}>
                                                            <p className='text-center fw600' style={{ fontSize: '28px' }}>Gracias por participar</p>
                                                            <p className='text-center fs-22 '></p>

                                                        </div>
                                                            <Row style={{ height: '18dvh', justifySelf: 'flex-end' }}>
                                                                <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} className='txtWhite ps-4'>
                                                                    <p className='txtWhite mb-1' style={{ textAlign: 'left' }}>Powered by</p>
                                                                    <div style={{ height: 'fit-content', textAlign: 'left' }} >
                                                                        <img style={{ width: '110px', fill: 'white' }} src="/imgs/logo_logan.png" />
                                                                    </div>
                                                                </Col>
                                                                <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} className='pe-4'>
                                                                    <p className='txtWhite mb-1' style={{ textAlign: 'right' }}>Sponsor <span className='fw800'>Oro</span></p>
                                                                    <div style={{ height: 'fit-content' }} className='endRow'>
                                                                        <img style={{ width: '90px' }} src="/imgs/ma_white.png" />
                                                                    </div>
                                                                </Col>
                                                            </Row></>
                }
                {currentQuestion <= 8 ? <div className='flex j-center mt-4'>
                    <Button className='gradient-green pt-1 pb-1' disabled={
                        currentQuestion === -1 && !isUserRegistered ? firstName.trim() === '' || lastName.trim() === '' || email.trim() === '' || company.trim() === '' || position.trim() === '' :
                            currentQuestion === 0 ? knownCauses.length === 0 :
                                currentQuestion === 1 ? recommendRate == null || recommendRate === "" :
                                    currentQuestion === 2 ? generalCalification == null || generalCalification.trim() === "" :
                                        currentQuestion === 3 ? sastifactionBlock.find(r => r == null) !== undefined :
                                            currentQuestion === 5 ? loveMoment.trim() === "" :
                                                currentQuestion === 6 ? futureAssistance == null :
                                                    currentQuestion === 7 ? technicalSatisfaction.find(r => r == null) !== undefined :
                                                        currentQuestion == 8 ? finalComment.trim() === "" :
                                                            false} style={{ width: '80vw' }} onClick={currentQuestion == 8 ? saveForm : nextQuestion}>
                        <div className='fw800 fs-18 txtBlack'>
                            {currentQuestion == 8 ? 'ENVIAR' : 'SIGUIENTE'}
                        </div>

                    </Button>
                </div> : ""}
            </div>


        </div>
    )
}

export default Survey