// Importamos las imágenes GIF o PNG de los personajes
import jugoGif from './jugo.gif';  
import jugoUno from './jugoUno.gif';
import jugoDos from './jugoDos.gif';  // Cambié esta línea para importar correctamente 'jugoDos'

const characters = [
  { id: 1, img: jugoGif },
  { id: 2, img: jugoUno },  // Asigné jugoUno a este personaje
  { id: 3, img: jugoDos },  // Asigné jugoDos a este personaje
];

// Componente que muestra un personaje al azar
const CharacterComponent = () => {
  const randomIndex = Math.floor(Math.random() * characters.length);
  const selectedCharacter = characters[randomIndex];  // Selecciona un personaje al azar

  return (
    <div>
      <img src={selectedCharacter.img} alt="personaje aleatorio" style={{ width: '200px', height: '500px' }} />
    </div>
  );
};

// Exportamos el componente
export default CharacterComponent;
