import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, orderBy, limit } from 'firebase/firestore';
import { db } from '../../../firebase'; // Asegúrate de que tu configuración de Firebase esté correcta
import { Col, Row, Spinner } from 'react-bootstrap';

export default function Ranking() {
    const [rankingData, setRankingData] = useState([]);
    const [loading, setLoading] = useState(true);

    // Obtener los datos de las puntuaciones de los usuarios
    useEffect(() => {
        const fetchRankingData = async () => {
            try {
                // Consulta los datos de la colección 'user_responses' en Firestore
                const q = query(
                    collection(db, 'user_responses'),
                    orderBy('score', 'desc'),
                    limit(30) // Limita a los primeros 10 documentos
                );
                const querySnapshot = await getDocs(q);

                const data = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                // Actualiza el estado con los datos obtenidos
                setRankingData(data);
            } catch (error) {
                console.error('Error al obtener los datos del ranking:', error);
            } finally {
                setLoading(false); // Finaliza la carga
            }
        };

        fetchRankingData();
    }, []);

    if (loading) {
        return <div className='flex j-center content a-center'>
            <Spinner animation="border" role="status" variant="" style={{ color: "#1cf100" }}>
                <span className="visually-hidden"></span>
            </Spinner>
        </div>;
    }

    return (
        <div className='content' style={{height:'100dvh', overflowY:'auto'}}>
            <Row className='pt-4'>
                <Col className='flex j-center'>
                    <div className='fitContentWidth'>
                        <img style={{ height: '35px' }} src="/imgs/media_day_logo.png" />
                        <p className='txtWhite mt-1 fs-18 me-1 mb-0' style={{ textAlign: 'right' }}>let's play!</p>
                    </div>
                </Col>
            </Row>
            <Row className='mt-3'>
                <Col className='txtWhite overflow-auto pb-3' style={{backgroundColor:'black'}}>
                    <p className='fw600 fs-22 txt-center mb-2'>Ranking de Jugadores</p>
                    <div className='ps-3 pe-3'>
                        <Row className='txtWhite'>
                            <Col xs={2}>
                                Puesto
                            </Col>
                            <Col xs={6}>
                                Jugador
                            </Col>
                            <Col xs={4} style={{ textAlign: 'right' }}>
                                Score
                            </Col>
                        </Row>
                        {rankingData.map((user, index) => (
                            <Row key={user.id || index} className='gradient-back txtBlack fw600 pt-2 pb-2 mb-1 br-5'>
                                <Col xs={1} className='fw600'>
                                    {index + 1}
                                </Col>
                                <Col xs={7} className='fw600 pe-0'>
                                    {user.nickname}
                                </Col>
                                <Col xs={4} style={{ textAlign: 'right' }} className='fw600 ps-0'>
                                    {user.score}
                                </Col>
                            </Row>
                        ))}
                    </div>
                </Col>
            </Row>
        </div>
    );
}
