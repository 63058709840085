import React, { useState, useEffect, useRef } from 'react';
import { collection, addDoc, onSnapshot, query, orderBy, Timestamp, doc, updateDoc, where, getDocs } from 'firebase/firestore';
import { db } from '../../../firebase';
import { Button, Col, FormControl, InputGroup, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';

const Interaction6 = ({ isHost = false }) => {
    const [userInfo, setUserInfo] = useState({});
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const messagesEndRef = useRef(null);  // Para manejar el autoscroll al final

    useEffect(() => {
        const uinfo = localStorage.getItem("userInfo");
        if (uinfo) {
            setUserInfo(JSON.parse(uinfo));
        }

        // Suscribirse a los mensajes en tiempo real, ordenados por timestamp
        const q = query(collection(db, 'chat_messages'), orderBy('timestamp', 'asc'));
        const unsubscribe = onSnapshot(q, (snapshot) => {
            const updatedMessages = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            console.log(updatedMessages)
            setMessages(updatedMessages);
            scrollToBottom();  // Autoscroll al final cuando llegan nuevos mensajes
        });

        return () => unsubscribe(); // Limpiar la suscripción al desmontar el componente
    }, []);

    const sendMessage = async () => {
        if (!userInfo || !userInfo.firstName || !userInfo.lastName) {
            console.error('Información del usuario no disponible.');
            return;
        }

        try {
            const chatMessage = {
                user: `${userInfo.nickname}`,
                message: message,
                timestamp: Timestamp.now(),
                isFaved: false
            };

            await addDoc(collection(db, 'chat_messages'), chatMessage);
            setMessage(''); // Limpiar el input después de enviar
        } catch (error) {
            console.error('Error al enviar mensaje:', error);
        }
    };

    // Función para desplazar el scroll al final
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const colorUsers = ['#1cf100', '#06dae5']
    const favComment = async (msgId, isFaved, nickname) => {
        try {
            // Referencia al documento del mensaje en 'chat_messages'
            const docRef = doc(db, 'chat_messages', msgId);

            // Actualizar el estado de 'isFaved'
            await updateDoc(docRef, {
                isFaved: !isFaved
            });

            // Hacer una consulta para encontrar el documento del usuario con el nickname especificado
            const userQuery = query(collection(db, 'user_responses'), where('nickname', '==', nickname));
            const userSnapshot = await getDocs(userQuery);

            // Asegurarse de que existe un usuario con el nickname especificado
            if (!userSnapshot.empty) {
                const userDoc = userSnapshot.docs[0];  // Tomar el primer documento que coincida con el nickname
                const userRef = doc(db, 'user_responses', userDoc.id);

                // Obtener el valor actual del puntaje (score)
                const currentScore = userDoc.data().score || 0;

                // Sumar 100 puntos al puntaje del usuario
                await updateDoc(userRef, {
                    score: !isFaved?  currentScore + 100 : currentScore - 100
                });

                console.log(`Se han sumado 100 puntos al usuario con nickname: ${nickname}`);
            } else {
                console.log(`No se encontró ningún usuario con el nickname: ${nickname}`);
            }
        } catch (error) {
            console.error("Error al realizar la actualización: ", error);
        }
    };

    return (
        <div className='content'>
            <Row className='pt-4 txtWhite' style={{ flex: '0 0 auto' }}>
                <Col className='flex j-center'>
                    <div className='fitContentWidth'>
                        <img style={{ height: '35px' }} src="/imgs/media_day_logo.png" />
                        <p className='txtWhite mt-1 fs-18 me-1 mb-0' style={{ textAlign: 'right' }}>let's chat!</p>
                    </div>
                </Col>
            </Row>

            <div style={{ width: '100%', height: 'calc(100dvh - 160px)', overflowY: 'auto', marginBottom: '20px' }}>
                {messages.map((msg, index) => (
                    <div key={index} style={{ padding: '5px' }} className='txtWhite'>
                        {isHost ?
                            <FontAwesomeIcon icon={faHeart} style={{ fontSize: '13px', color: msg.isFaved ? index % 2 == 0 ? colorUsers[1] : colorUsers[0] : 'white' }} className='me-1' onClick={() => favComment(msg.id, msg.isFaved, msg.user)} />
                            : ''}
                        <strong style={{ color: index % 2 == 0 ? colorUsers[0] : colorUsers[1] }}>{msg.user}</strong> {msg.message}
                        {!isHost && msg.isFaved ? <span className='fitContentWidth ps-2 pe-2 ms-1' style={{ border: "1px solid white", borderRadius: '15px' }}>
                            <FontAwesomeIcon icon={faHeart} style={{ fontSize: '13px', color: 'red' }} className='' />
                        </span> : ""}
                    </div>
                ))}
                <div ref={messagesEndRef} />  {/* Ref para el autoscroll al final */}
            </div>
            <div style={{ position: 'absolute', bottom: 0, width: '90vw' }} className='pb-2'>
                <div style={{ width: '100%', margin: '0 0', position: 'relative' }}>
                    <FormControl
                        type="text"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="Escribe tu mensaje..."
                        style={{ paddingRight: '35px' }}
                        onKeyDown={(e) => e.code == "Enter" ? sendMessage() : ""}
                    />

                    {/* SVG de checkmark directamente en JSX con color verde */}
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                        style={{
                            width: '24px',
                            height: '24px',
                            position: 'absolute',
                            top: '50%',
                            right: '10px',
                            transform: 'translateY(-50%)',
                            cursor: 'pointer',
                            fill: 'lime' // Aplica el color verde
                        }}
                        onClick={sendMessage}
                    >
                        <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                    </svg>

                </div>
            </div>
        </div>

    );
};

export default Interaction6;
