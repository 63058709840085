import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { db, saveUserResponse } from '../../../firebase';
import '../../../App.css';

const WaitingRoom = ({ onLogin, setGameActive }) => {
    const [gameCode, setGameCode] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [position, setPosition] = useState('');
    const [birthDate, setBirthDate] = useState('');
    const [gender, setGender] = useState('');
    const [waitingForHost, setWaitingForHost] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {

        const gameStateDocRef = doc(db, 'gameState', 'current');
        const unsubscribe = onSnapshot(gameStateDocRef, (doc) => {
            if (doc.exists()) {
                const gameStateData = doc.data();
                console.log(gameStateData.activeInteraction)
                if (gameStateData.gameActive) {

                    // Activamos el juego con setGameActive
                    setGameActive(true);

                    // Condiciones para redirigir a la interacción adecuada
                    /*if (gameStateData.interaction6Active) {
                        navigate('/interaction6');
                    } else if (gameStateData.interaction4Active) {
                        navigate('/interaction4');
                    } else if (gameStateData.isSecondInteraction) {
                        navigate('/interaction1');
                    } else {
                        navigate('/interaction3');

                    }*/
                    switch (gameStateData.activeInteraction) {
                        case 1:
                            console.log("Redirecciono a interaccion 1");
                            navigate('/interaction1');
                            break;
                        case 2:
                            console.log("Redirecciono a interaccion 2");
                            navigate('/interaction2');
                            break;
                        case 3:
                            console.log("Redirecciono a interaccion 3");
                            navigate('/interaction3');
                            break;
                        case 4:
                            console.log("Redirecciono a interaccion 4");
                            navigate('/interaction4');
                            break;
                        case 5:
                            console.log("Redirecciono a interaccion 5");
                            navigate('/interaction5');
                            break;
                        case 6:
                            console.log("Redirecciono a interaccion 6");
                            navigate('/interaction6');
                            break;
                    }
                }
            }
        });

        return () => unsubscribe();

    }, [navigate]);



    return (
        <div className="login-container content txtWhite ps-4 pe-4 fs-14 pb-4">
            <Row className='pt-3 ps-2 pe-2 mb-2'>
                <Col className='flex a-center'>
                    <img style={{ height: '30px' }} src={'/imgs/caam.png'} />
                </Col>
                <Col className='endRow a-center'>
                    <img style={{ height: '50px' }} src={'/imgs/md_sticker.png'} />
                </Col>
            </Row>


            <div className="text-center">
                <Row style={{ height: 'calc(82dvh - 100px)' }} className='flex a-center'>
                    <div>

                        <img style={{ width: '85vw' }} src="/imgs/media_day_logo.png" className='mb-4' />
                        <p style={{ textAlign: 'center', fontSize: '28px' }} className='fw600 mb-4'>¡Gracias!</p>
                        <p style={{ textAlign: 'center' }} className='fs-22 fw500 mb-1'>Cuando el Host lo indique volvé a ingresar para comenzar a jugar</p>

                    </div>
                </Row>
                <Row style={{ height: '18dvh' }}>
                    <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} className='txtWhite'>
                        <p className='txtWhite mb-1' style={{ textAlign: 'left' }}>Powered by</p>
                        <div style={{ height: 'fit-content', textAlign: 'left' }} >
                            <img style={{ width: '110px', fill: 'white' }} src="/imgs/logo_logan.png" />
                        </div>
                    </Col>
                    <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <p className='txtWhite mb-1' style={{ textAlign: 'right' }}>Sponsor <span className='fw800'>Oro</span></p>
                        <div style={{ height: 'fit-content' }} className='endRow'>
                            <img style={{ width: '90px' }} src="/imgs/ma_white.png" />
                        </div>
                    </Col>
                </Row>
                

            </div>



        </div>
    );
};

export default WaitingRoom;
