import React, { useState, useEffect } from 'react';
import { doc, updateDoc, getDoc, query, collection, orderBy, limit, getDocs, onSnapshot } from 'firebase/firestore';
import { db } from '../../../firebase';
import { Col, Row } from 'react-bootstrap';
import confetti from 'canvas-confetti';

export default function Interaction7() {
    const [userInfo, setUserInfo] = useState({});
    const [winners, setWinners] = useState([]);
    const [showWinnerInfo, setShowWinnerInfo] = useState(false)

    useEffect(() => {
        const uinfo = localStorage.getItem("userInfo");
        if (uinfo) {
            setUserInfo(JSON.parse(uinfo));
        }

        const gameStateDocRef = doc(db, 'gameState', 'current');
        const unsubscribe = onSnapshot(gameStateDocRef, (doc) => {
            if (doc.exists()) {
                const gameStateData = doc.data();
                console.log(gameStateData.lotteryWinners)
                setWinners(gameStateData.lotteryWinners)

                for (let w of gameStateData.lotteryWinners) {
                    if (w.nickname == uinfo.nickname) {
                        setShowWinnerInfo(true)
                    }
                }

                triggerConfetti();
            }
        });

        return () => unsubscribe();
    }, []);
    const colorUsers = ['#1cf100', '#06dae5']

    const triggerConfetti = () => {
        // Configuración básica del confeti
        confetti({
            particleCount: 300,
            spread: 200,
            origin: { y: 0 }, // Empieza desde la parte superior
        });
    };

    return (
        <div className='content txtWhite'>
            <Row className='pt-4 txtWhite' style={{ flex: '0 0 auto' }}>
                <Col className='flex j-center'>
                    <div className='fitContentWidth'>
                        <img style={{ height: '35px' }} src="/imgs/media_day_logo.png" />
                    </div>
                </Col>
            </Row>
            <h2 className='txt-center mt-2'>Sorteo</h2>


            {winners.length > 0 && (
                <div>
                    {winners.map((winner, index) => (
                        <div key={index} style={{ listStyle: 'none' }} className='mb-3'>
                            <div className='txt-center fw600 fs-18'>{winner.prize}</div>
                            <div className='fw600 txt-center' style={{ color: index % 2 == 0 ? colorUsers[0] : colorUsers[1] }}>{`${winner.firstName} ${winner.lastName} ${winner.nickname ? `- ${winner.nickname}` : ""}`}</div>
                        </div>
                    ))}

                </div>
            )}
            {showWinnerInfo ?
                <p className='mt-5 txt-center fw600 fs-18'>Comunicate con Lucia Ricaldoni (lricaldoni@agenciasdemedios .com .ar) para coordinar la entrega</p>
                :
                <></>
            }

        </div>
    );
}
