import React, { useState, useEffect } from 'react';
import { Container, Row, Button, Modal, Col } from 'react-bootstrap';
import { collection, onSnapshot, doc, updateDoc, increment, query, orderBy, getDocs } from 'firebase/firestore';
import { db } from '../../../firebase';
import { useNavigate } from 'react-router-dom';  // Importar useNavigate
import '../../../App.css';
import Ranking from './Ranking';
import moment from 'moment';

export default function Interaction3() {
    const [userInfo, setUserInfo] = useState({});
    const [interaction, setInteraction] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [timeLeft, setTimeLeft] = useState(10);  // Tiempo inicial a 20 segundos
    const [showResult, setShowResult] = useState(false);  // Controla si se muestra el modal
    const [selectedOption, setSelectedOption] = useState(null);
    const [correctAnswer, setCorrectAnswer] = useState(false);
    const [userRank, setUserRank] = useState(null);  // Estado para la posición del usuario en el ranking
    const [userScore, setUserScore] = useState(0);  // Estado para el puntaje del usuario
    const navigate = useNavigate();

    // Monitorear los cambios en el estado de interacción en Firestore
    useEffect(() => {
        const uinfo = localStorage.getItem("userInfo");
        if (uinfo) {
            setUserInfo(JSON.parse(uinfo));
        }

        const gameStateDocRef = doc(db, 'gameState', 'current');
        const unsubscribe = onSnapshot(gameStateDocRef, (doc) => {
            if (doc.exists()) {
                const gameStateData = doc.data();
                if (gameStateData.showRanking) {
                    console.log("No hay preguntas")
                    navigate('/ranking');
                    return;
                }

                const currentDateTime = moment();
                let timeLeft1 = 10; // Tiempo por defecto

                // Obtén el índice actual desde localStorage
                const storedQuestionIndex = localStorage.getItem('currentQuestionIndex');
                const storedStartTime = localStorage.getItem('startTime');

                // Verifica si hay un índice almacenado
                if (storedQuestionIndex === null) {
                    // Si no hay índice almacenado, inicializa el valor
                    localStorage.setItem('currentQuestionIndex', gameStateData.currentQuestionIndex);
                    localStorage.setItem('startTime', moment().toISOString());
                    timeLeft1 = 10; // Tiempo inicial
                } else {
                    // Si hay un índice almacenado, verifica si coincide
                    if (storedQuestionIndex != gameStateData.currentQuestionIndex) {
                        // Si no coincide, actualiza el localStorage
                        localStorage.setItem('currentQuestionIndex', gameStateData.currentQuestionIndex);
                        localStorage.setItem('startTime', moment().toISOString());
                        timeLeft1 = 10; // Reinicia el temporizador a 10 segundos
                    } else {
                        // Si coincide, calcula el tiempo restante
                        const startTime = moment(storedStartTime);
                        const elapsed = currentDateTime.diff(startTime, 'seconds');
                        timeLeft1 = Math.max(10 - elapsed, 0);
                    }
                }
                setTimeLeft(timeLeft1);

                if (gameStateData.currentQuestionIndex !== currentQuestionIndex && gameStateData.currentQuestionIndex <= (interaction.length - 1)) {
                    setCurrentQuestionIndex(gameStateData.currentQuestionIndex);
                    setSelectedOption(null);
                    setCorrectAnswer(false);
                    setShowResult(false);
                }
            }
        });

        return () => unsubscribe(); // Cleanup listener
    }, [currentQuestionIndex, interaction.length]);

    // Cargar las preguntas de la colección
    useEffect(() => {
        const unsubscribe = onSnapshot(collection(db, 'questions_8888'), (snapshot) => {
            const questionsData = snapshot.docs.map(doc => doc.data());
            setInteraction(questionsData);
        }, (error) => {
            console.error("Error al obtener preguntas: ", error);
        });

        return () => unsubscribe();
    }, []);

    // Obtener el ranking en tiempo real y calcular la posición del usuario
    useEffect(() => {
        const fetchRankingData = async () => {
            try {
                const q = query(
                    collection(db, 'user_responses'),
                    orderBy('score', 'desc')
                );
                const querySnapshot = await getDocs(q);

                const users = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                const userPosition = users.findIndex(user => user.email === userInfo.email) + 1;
                setUserRank(userPosition); // Guardar la posición del usuario en el ranking
            } catch (error) {
                console.error('Error al obtener los datos del ranking:', error);
            }
        };

        if (userInfo.email) {
            fetchRankingData();
        }
    }, [userScore, userInfo.email]); // Vuelve a calcular la posición cuando cambie el puntaje

    // Manejo del tiempo restante
    useEffect(() => {
        let timer;
        if (timeLeft > 0 && interaction.length > 0) {
            timer = setTimeout(() => setTimeLeft(prev => prev - 1), 1000);
        } else if (timeLeft === 0 && interaction.length > 0 && !showResult) {
            // Cuando el tiempo se acaba, mostrar el modal
            setShowResult(true);
        }
        return () => clearTimeout(timer);
    }, [timeLeft, selectedOption, interaction, currentQuestionIndex, showResult]);

    // Función para manejar la respuesta correcta y sumar puntos
    const handleCorrectAnswer = async () => {
        try {
            const totalQuestions = interaction.length; // Cantidad total de preguntas en la interacción
            const maxPoints = 1000 / totalQuestions;  // Distribuir 1000 puntos entre todas las preguntas
            const timeFactor = timeLeft / 20; // Factor basado en el tiempo restante
            const pointsToAdd = maxPoints * timeFactor;  // Puntos que se reducen a medida que pasa el tiempo
            const userRef = doc(db, 'user_responses', userInfo.email);
            await updateDoc(userRef, {
                score: increment(pointsToAdd)
            });
            setUserScore(prev => prev + pointsToAdd);
        } catch (error) {
            console.error("Error al actualizar el puntaje:", error);
        }
    };

    // Función para manejar la selección de la respuesta
    const handleAnswer = (index) => {
        if (!showResult && interaction.length > 0) {
            setSelectedOption(index);
            const currentQuestion = interaction[currentQuestionIndex];
            const selectedText = currentQuestion.opciones[index];
            const isCorrect = selectedText === currentQuestion.respuesta;
            setCorrectAnswer(isCorrect);

            if (isCorrect) {
                handleCorrectAnswer();  // Actualiza los puntos si es correcto
            }

            // No mostrar el modal inmediatamente, esperar a que el tiempo termine
        }
    };

    // Manejo de errores o estados incompletos
    if (!userInfo || !userInfo.firstName || !userInfo.lastName) {
        return <div>Error: Datos de usuario incompletos o indefinidos</div>;
    }

    if (interaction.length === 0) {
        return <div>No se encontraron preguntas.</div>;
    }

    const currentQuestion = interaction[currentQuestionIndex];

    return (
        <div className="questions-page" style={{ backgroundColor: '#FFE600', height: '100dvh' }}>
            <Row className='pt-4'>
                <Col className='flex j-center'>
                    <div className='fitContentWidth'>
                        <img style={{ height: '35px' }} src="/imgs/media_day_negro.png" />
                        <p className=' mt-1 fs-18 me-1 mb-0' style={{ textAlign: 'right' }}>let's play!</p>
                    </div>
                </Col>
            </Row>
            <Row className="text-center ps-3 pe-3">
                <Col>
                    <Row className='mb-3 ps-3 pe-3' style={{ height: '80px' }}>
                        <Col>
                            <div style={{ position: 'relative' }} className='ps-0 pe-0'>
                                <div className='flex j-center'>
                                    <img src="/imgs/ma_color.png" style={{ height: '80px' }} />
                                </div>
                                <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
                                    <div style={{ position: 'relative' }} className='ps-0'>
                                        <div className='fitContentWidth'>
                                            <img style={{ width: '50px' }} className='br-5' src="/imgs/timer2.png" />
                                        </div>
                                        <div className='fw800 txt-center ' style={{ position: 'absolute', bottom: 4, left: 7.5, width: '35px', height: '35px', borderRadius: '50%', backgroundColor: '#7e61d8', fontSize: '25px' }}>
                                            {timeLeft}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <div style={{ position: 'relative', backgroundColor: 'white', borderRadius: '25px 0 0 25px' }} className='flex a-center'>
                        <div className='txt-center flex a-center j-center fw800 fs-22' style={{ height: '50px', width: '50px', backgroundColor: '#7e61d8', borderRadius: '50%', position: 'absolute', left: '0' }}>
                            {currentQuestionIndex + 1}
                        </div>
                        <div style={{ paddingLeft: '50px', lineHeight: '15px' }} className='pe-2 '>
                            {currentQuestion.pregunta}
                        </div>
                    </div>

                    <Row className="mt-3">
                        {currentQuestion.opciones.map((option, index) => (
                            <div className="mb-3 pe-0" key={index} style={{ backgroundColor: 'white', position: 'relative', borderRadius: '25px 0 0 25px' }}>

                                <div className='txt-center flex a-center j-center fw800 fs-22' style={{ height: '58px', width: '58px', backgroundColor: '#7e61d8', borderRadius: '50%', position: 'absolute', left: '0', opacity: selectedOption !== null && selectedOption !== index ? 0.9 : 1, marginLeft: '-10px' }}>
                                    {String.fromCharCode(65 + index)}
                                </div>
                                <Button
                                    onClick={() => handleAnswer(index)}
                                    disabled={selectedOption !== null}
                                    className={`w-100 noBorder txtBlack`}
                                    style={{ opacity: selectedOption !== null && selectedOption !== index ? 0.5 : 1, backgroundColor: 'transparent' }}
                                >
                                    <div className='txtBlack flex a-center' style={{ lineHeight: '15px', paddingLeft: '30px', minHeight: '45px' }} >
                                        {option}
                                    </div>
                                </Button>
                            </div>
                        ))}
                    </Row>

                    <Modal
                        show={showResult}
                        centered
                        onHide={() => setShowResult(false)}
                        backdrop="static"
                    >
                        <Modal.Body
                            style={{
                                backgroundColor: '#0d0d0d', // Fondo negro intenso
                                color: '#4caf50', // Verde más suave, similar a un tono esmeralda
                                textAlign: 'center',
                                padding: '20px',
                                borderRadius: '2px',
                            }}
                        >
                            <h2
                                style={{
                                    fontSize: '28px',
                                    fontWeight: 'bold',
                                    marginBottom: '15px',
                                    color: '#8A2BE2', // Título en verde vibrante
                                }}
                            >
                                {correctAnswer ? "¡Correcto!" : "¡Incorrecto!"}
                            </h2>
                            <p
                                style={{
                                    color: '#ffffff', // Texto blanco
                                }}
                            >
                                Puntuación actual: {userScore.toFixed()} Puntos
                            </p>
                            <p
                                style={{
                                    color: '#ffffff', // Texto blanco
                                }}
                            >
                                Tu posición en el ranking: #{userRank}
                            </p>
                        </Modal.Body>
                    </Modal>
                </Col>
            </Row>
        </div>
    );
}
