import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc, increment, collection, query, orderBy, getDocs } from 'firebase/firestore';
import { Modal, Button, Spinner, Row, Col } from 'react-bootstrap';
import { db } from '../../../firebase';

const QuestionScreen = () => {
  const { category } = useParams(); // Recibe la categoría seleccionada de la URL
  const [questionData, setQuestionData] = useState(null);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [showModal, setShowModal] = useState(false); // Controla la visibilidad del modal
  const [showTimeoutModal, setShowTimeoutModal] = useState(false); // Controla la visibilidad del modal por timeout
  const [isCorrect, setIsCorrect] = useState(false); // Para saber si la respuesta fue correcta o incorrecta
  const [timeLeft, setTimeLeft] = useState(10); // Contador de 10 segundos
  const [timeOver, setTimeOver] = useState(false); // Indica si el tiempo se ha terminado
  const [userScore, setUserScore] = useState(0); // Puntuación del usuario
  const [pointsEarned, setPointsEarned] = useState(0); // Puntos obtenidos en esta pregunta
  const [userRank, setUserRank] = useState(null); // Posición del usuario en el ranking
  const [timer, setTimer] = useState(null); // Controlar el temporizador
  const navigate = useNavigate(); // Para redirigir
  const userInfo = JSON.parse(localStorage.getItem('userInfo')); // Obtener datos del usuario de localStorage

  useEffect(() => {
    const fetchQuestion = async () => {
      try {
        const docRef = doc(db, 'questions_spin', category); // Consulta la categoría que llegó
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setQuestionData(docSnap.data());
        } else {
          console.error("No se encontró la pregunta para la categoría:", category);
        }
      } catch (error) {
        console.error("Error obteniendo la pregunta:", error);
      }
    };

    fetchQuestion();
  }, [category]);

  // Manejar el temporizador
  useEffect(() => {
    if (timeLeft > 0 && !timeOver) {
      const newTimer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      setTimer(newTimer);
      return () => clearTimeout(newTimer); // Limpia el temporizador al desmontar
    } else if (timeLeft === 0) {
      setTimeOver(true); // Cuando el tiempo llegue a 0
      setShowTimeoutModal(true); // Muestra el modal cuando el tiempo se acaba
    }
  }, [timeLeft, timeOver]);

  // Función para obtener la posición en el ranking
  const fetchUserRanking = async () => {
    try {
      const userResponsesRef = collection(db, 'user_responses');
      const q = query(userResponsesRef, orderBy('score', 'desc')); // Ordenar por puntaje de mayor a menor
      const querySnapshot = await getDocs(q);

      const users = querySnapshot.docs.map((doc) => doc.data());
      const userPosition = users.findIndex((user) => user.email === userInfo.email) + 1; // Obtener la posición del usuario

      setUserRank(userPosition); // Actualizar el estado de la posición del usuario
    } catch (error) {
      console.error("Error al obtener la posición del usuario en el ranking:", error);
    }
  };

  // Función para manejar la respuesta correcta y sumar puntos
  const handleCorrectAnswer = async () => {
    try {
      const maxPoints = 200; // Máximo de 1000 puntos por pregunta
      const timeFactor = timeLeft / 10; // Factor basado en el tiempo restante (10 segundos es el máximo)
      const pointsToAdd = (maxPoints * timeFactor) >= 25 ? Math.round(maxPoints * timeFactor) : 25; // Puntos que se reducen a medida que pasa el tiempo
      setPointsEarned(pointsToAdd); // Guardar los puntos obtenidos en el estado
      const userRef = doc(db, 'user_responses', userInfo.email); // Referencia a la colección del usuario

      // Actualizar el puntaje en Firestore
      await updateDoc(userRef, {
        score: increment(pointsToAdd)
      });

      // Actualizar el puntaje local
      setUserScore(prev => prev + pointsToAdd);

      // Obtener la posición en el ranking después de actualizar el puntaje
      await fetchUserRanking();
    } catch (error) {
      console.error("Error al actualizar el puntaje:", error);
    }
  };

  const handleAnswerClick = (answer) => {
    if (timeOver) return; // No permitir respuestas si el tiempo ha terminado

    clearTimeout(timer); // Detenemos el temporizador
    setSelectedAnswer(answer);
    console.log("Respuesta", answer);
    console.log("Correcta", questionData.respuesta)
    const isAnswerCorrect = answer === questionData.respuesta; // Verifica si la respuesta es correcta
    console.log("Resultado", isAnswerCorrect)
    setIsCorrect(isAnswerCorrect); // Actualizamos si la respuesta es correcta

    if (isAnswerCorrect) {
      handleCorrectAnswer(); // Actualiza los puntos si es correcto
    }

    setShowModal(true); // Muestra el modal
  };

  const handleCloseModal = () => {
    setShowModal(false); // Cierra el modal
    navigate('/interaction2'); // Redirige a la página de la ruleta
  };

  const handleTimeoutClose = () => {
    setShowTimeoutModal(false); // Cierra el modal del timeout
    navigate('/interaction2'); // Redirige a la página de la ruleta
  };

  if (!questionData) {
    return <div className='flex j-center content a-center'>
      <Spinner animation="border" role="status" variant="" style={{ color: "#1cf100" }}>
        <span className="visually-hidden"></span>
      </Spinner>
    </div>;
  }

  return (
    <div className="content">
      <Row className='pt-4'>
        <Col className='flex j-center'>
          <div className='fitContentWidth'>
            <img style={{ height: '35px' }} src="/imgs/media_day_logo.png" />
            <p className='txtWhite mt-1 fs-18 me-1 mb-0' style={{ textAlign: 'right' }}>let's play!</p>
          </div>
        </Col>
      </Row>
      <Row className='mb-2 mt-3 p-2 flex j-center'>
        <Col className='flex a-center' style={{ position: 'relative' }}>
          <div style={{ position: 'absolute', left: 0 }}>
            <div style={{ position: 'relative' }} className='ps-0'>
              <div className='fitContentWidth'>
                <img style={{ width: '50px' }} className='br-5' src="/imgs/timer1.png" />
              </div>
              <div className='fw800 txt-center ' style={{ position: 'absolute', bottom: 4, left: 7, width: '35px', height: '35px', borderRadius: '50%', backgroundColor: '#1cf100', fontSize: '25px' }}>
                {timeLeft}
              </div>
            </div>
          </div>

          <div className='txt-center txtWhite fw600 fs-26' style={{ paddingLeft: '50px', lineBreak: 'auto' }}>
            {category}
          </div>
        </Col>
      </Row>

      <Row className='flex j-center mt-3'>
        <Col style={{ borderLeft: '3.5px solid #1cf100', maxWidth: '80vw' }} className='ps-0 pe-0'>
          <p className='txt-center txtWhite fs-22 mb-0' style={{ lineHeight: '22px' }}>{questionData.pregunta}</p>
        </Col>
      </Row>
      <div className="options-container mt-4">
        {questionData.opciones.map((option, index) => (

          <Button disabled={timeOver} onClick={() => handleAnswerClick(option)} className='gradient-back noBorder mb-2' style={{ minHeight: '50px', marginBottom: '-7px', width: '80vw', opacity: selectedAnswer !== null && selectedAnswer !== option ? 0.5 : 1 }}>
            <div className={`fw600 txtBlack ${selectedAnswer === option ? 'selected' : ''}`} style={{ fontSize: '28px', lineHeight: '26px' }}>
              {option}
            </div>
          </Button>
        ))}
      </div>

      {/* Modal para mostrar si la respuesta es correcta o incorrecta */}
      <Modal
        show={showModal}
        centered
        onHide={handleCloseModal}
        backdrop="static"
      >
        <Modal.Body
          style={{
            backgroundColor: '#0d0d0d', // Fondo negro intenso
            color: '#4caf50', // Verde más suave, similar a un tono esmeralda
            textAlign: 'center',
            padding: '20px',
            borderRadius: '2px',
          }}
        >
          <h2
            style={{
              fontSize: '28px',
              fontWeight: 'bold',
              marginBottom: '15px',
              color: isCorrect ? '#1cf100' : '#ff0000', // Título en verde vibrante
            }}
          >
            {isCorrect ? "¡Correcto!" : "¡Incorrecto!"}
          </h2>
          {isCorrect && (
            <>
              <p
                style={{
                  color: '#ffffff', // Texto blanco
                }}
              >
                ¡Has ganado {pointsEarned} puntos!
              </p>
              <p
                style={{
                  color: '#ffffff', // Texto blanco
                }}
              >
                Tu posición en el ranking es #{userRank}
              </p>
            </>

            

            
            
          )}
        </Modal.Body>
        <Modal.Footer
          style={{
            backgroundColor: '#0d0d0d', // Fondo negro intenso
            borderTop: 'none',
          }}
        >
          <Button
            style={{
              backgroundColor: '#1cf100',
              borderColor: '#1cf100',
            }}
            onClick={handleCloseModal}
          >
            Volver a girar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal para mostrar si la respuesta es correcta o incorrecta */}
      <Modal
        show={showModal}
        centered
        onHide={handleCloseModal}
        backdrop="static"
      >
        <Modal.Body
          style={{
            backgroundColor: '#0d0d0d', // Fondo negro intenso
            color: '#4caf50', // Verde más suave, similar a un tono esmeralda
            textAlign: 'center',
            padding: '20px',
            borderRadius: '2px',
          }}
        >
          <h2
            style={{
              fontSize: '28px',
              fontWeight: 'bold',
              marginBottom: '15px',
              color: isCorrect ? '#1cf100' : '#ff0000',
            }}
          >
            {isCorrect ? "¡Correcto!" : "¡Incorrecto!"}
          </h2>
          {isCorrect && (
            <>
              <p
                style={{
                  color: '#ffffff', // Texto blanco
                }}
              >
                ¡Has ganado {pointsEarned} puntos!
              </p>
              <p
                style={{
                  color: '#ffffff', // Texto blanco
                }}
              >
                Tu posición en el ranking es #{userRank}
              </p>
            </>
          )}
        </Modal.Body>
        <Modal.Footer
          style={{
            backgroundColor: '#0d0d0d', // Fondo negro intenso
            borderTop: 'none',
            justifyContent: 'center', // Centra el contenido del footer
          }}
        >
          <Button
            style={{
              backgroundColor: '#1cf100',
              borderColor: '#1cf100',
            }}
            onClick={handleCloseModal}
          >
            Volver a girar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal para mostrar cuando el tiempo se agota */}
      <Modal
        show={showTimeoutModal}
        centered
        onHide={handleTimeoutClose}
        backdrop="static"
      >
        <Modal.Body
          style={{
            backgroundColor: '#0d0d0d', // Fondo negro intenso
            color: '#4caf50', // Verde más suave, similar a un tono esmeralda
            textAlign: 'center',
            padding: '20px',
            borderRadius: '2px',
          }}
        >
          <h2
            style={{
              fontSize: '28px',
              fontWeight: 'bold',
              marginBottom: '15px',
              color: '#ff0000', 
            }}
          >
            ¡Se acabó el tiempo!
          </h2>
          <p
            style={{
              color: '#ffffff', // Texto blanco
            }}
          >
            Ya no es posible responder . Vuelve a girar la ruleta
          </p>
        </Modal.Body>
        <Modal.Footer
          style={{
            backgroundColor: '#0d0d0d',
            borderTop: 'none',
            justifyContent: 'center', // Centra el contenido del footer
          }}
        >
          <Button
            style={{
              backgroundColor: '#1cf100',
              borderColor: '#1cf100',
            }}
            onClick={handleTimeoutClose}
          >
            Volver a girar
          </Button>
        </Modal.Footer>
      </Modal>


    </div>
  );
};

export default QuestionScreen;
