import { doc, onSnapshot } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { db } from '../../../firebase'

function Menu() {
    const [currentInteraction, setCurrentInteraction] = useState(0)
    const [hasPoints, setHasPoints] = useState(true)
    const userData = JSON.parse(localStorage.getItem('userInfo'));
    const [myScore, setMyScore] = useState(0)

    useEffect(() => {

        const gameStateDocRef = doc(db, 'gameState', 'current');
        const unsubscribe = onSnapshot(gameStateDocRef, (doc) => {
            if (doc.exists()) {
                const gameStateData = doc.data();
                console.log("Active interaction", gameStateData.activeInteraction)
                setCurrentInteraction(gameStateData.activeInteraction);
            }
        });

        const userDocRef = doc(db, 'user_responses', userData.email);
        const unsubscribeUser = onSnapshot(userDocRef, (doc) => {
            if (doc.exists()) {
                console.log(doc.data.score)
                const user = doc.data();
                setMyScore(user.score);
            }
        });

        return () => {
            unsubscribe();
            unsubscribeUser();
        };

        
    }, []);

    const [interactions, setInteractions] = useState([
        { title: 'Verdadero y falso', id: 1, link: '/interaction1', isAvailable: true },
        { title: 'Giremos', id: 2, link: '/interaction2', isAvailable: false },
        { title: 'Trivia Mercado Ads', id: 3, link: '/interaction3', isAvailable: false },
        { title: 'Minigame', id: 4, link: '/interaction4', isAvailable: false },
        { title: 'GEN IA', id: 5, link: '/interaction5', isAvailable: false },
        { title: 'Chat Streaming', id: 6, link: '/interaction6', isAvailable: false },
        { title: 'The winner is', id: 7, link: '/interaction7', isAvailable: false },
    ])

    return (
        <div className='content'>
            <Row className='pt-4'>
                <Col className='flex j-center'>
                    <div className='fitContentWidth'>
                        <img style={{ height: '35px' }} src="/imgs/media_day_logo.png" />
                        <p className='txtWhite mt-1 fs-18 me-1 mb-0' style={{ textAlign: 'right' }}>let's play!</p>
                    </div>
                    {hasPoints ? <div className='ps-2 flex a-center'>
                        <Row>
                            <Col style={{ borderLeft: '1px solid white' }} className='ms-3'>
                                <p style={{ color: '#06dae5', fontSize: '30px', height: 'min-content', lineHeight: '25px', fontWeight: 200 }} className='txt-center mb-0'>{myScore}</p>
                                <p className='txtWhite text-center fs-18 mb-0' style={{ fontWeight: 200 }}>Score</p>
                            </Col>
                        </Row>
                    </div> : ''}
                </Col>
            </Row>
            <Row className='mt-4' style={{ height: 'calc(95dvh - 100px)' }}>
                <Col className='flex a-center j-center' style={{ flexDirection: 'column' }} >

                    {interactions.map((r, i) => i + 1 <= currentInteraction ? (

                        <div className={`radiusButton noBorder pt-1 pb-1 mb-3 gradient-green txt-center`} style={{ width: '60vw', position:'relative' }}>
                            {i==2 ? <img src='/imgs/ma_color_back.png' style={{width:'60px', position:'absolute', left:0, top:'50%', marginLeft:'-40px',transform: 'translateY(-50%)', borderRadius:'10px'}}/> : ''}
                            <div className='fs-22 fw600 ' style={{ color: 'black' }}>
                                {r.title}
                            </div>
                        </div>
                    ) : (
                        <div disabled={true} className={`radiusButton noBorder pt-1 pb-1 mb-3 gradient-grey txt-center`} style={{ width: '60vw', position:'relative' }}>
                            {i==2 ? <img src='/imgs/ma_color_back.png' style={{width:'60px', position:'absolute', left:0, top:'50%', marginLeft:'-40px', transform: 'translateY(-50%)', borderRadius:'10px'}}/> : ''}
                            <div className='fs-22 fw600 ' style={{ color: 'white' }}>
                                {r.title}
                            </div>
                        </div>
                    )

                    )}
                </Col>
            </Row>

            {/* <Row className='p-2 flex j-center'>

                <Button className='gradient-green noBorder' style={{ height: '70px', marginBottom: '-7px', width:'80vw' }}>
                    <div className='fw600' style={{fontSize:'28px'}}>
                        Verdadero
                    </div>
                </Button>
                <div className="circle-or-container">
                    <span className="circle-or">o</span>
                </div>

                <Button className='gradient-red noBorder' style={{ height: '70px', width:'80vw' }}>
                    <div className='fw600' style={{fontSize:'28px'}}>
                        Falso
                    </div>
                </Button>
            </Row>

            <Row className='mb-2 p-2 flex j-center'>
                <div style={{ position: 'relative', width: '80vw' }} className='ps-0'>
                    <img style={{ width: '80vw' }} className='br-5' src="https://images.unsplash.com/photo-1432405972618-c60b0225b8f9?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxleHBsb3JlLWZlZWR8Mnx8fGVufDB8fHx8fA%3D%3D" />
                    <div className='fitContentWidth' style={{ position: 'absolute', bottom: 5, right: 3 }}>
                        <img style={{ width: '50px' }} className='br-5' src="/imgs/timer1.png" />
                    </div>
                    <div className='fw800 txt-center' style={{ position: 'absolute', bottom: 9, right: 10.5, width: '35px', height: '35px', borderRadius: '50%', backgroundColor: '#1cf100', fontSize: '25px' }}>
                        {5}
                    </div>
                </div>
            </Row>

            <Row className='mb-2 p-2 flex j-center'>
                <Col style={{borderLeft: '3.5px solid #1cf100', maxWidth:'80vw'}} className='ps-0 pe-0'>
                    <p className='txt-center txtWhite fs-18 mb-0'>{'Pregunta 1 '}</p>
                </Col>
            </Row> */}

        </div>
    )
}

export default Menu