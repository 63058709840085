import React, { useState, useEffect } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { Wheel } from 'react-custom-roulette';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const WheelOption = ({ text, backgroundColor }) => {
  return (
    <div
      style={{
        backgroundColor: backgroundColor,
        width: '80px', // Ajusta el ancho según sea necesario
        whiteSpace: 'normal',
        overflowWrap: 'break-word',
        textAlign: 'center',
        fontWeight: 600,
        fontSize: '16px',
        padding: '10px', // Ajuste de relleno si lo deseas
      }}
    >
      {text}
    </div>
  );
};

const data = [
  { option: 'TVA', style: { backgroundColor: '#1cf100' } },
  { option: 'TVC', style: { backgroundColor: 'white' } },
  { option: 'RADIO', style: { backgroundColor: '#1cf100' } },
  { option: 'REDES SOCIALES', style: { backgroundColor: 'white' } },
  { option: 'CINE', style: { backgroundColor: '#1cf100' } },
  { option: 'CANALES DE STREAMING', style: { backgroundColor: 'white' } },
  { option: 'VOD', style: { backgroundColor: '#1cf100' } },
  { option: 'RETAIL MEDIA', style: { backgroundColor: 'white' } },
  { option: 'VÍA PÚBLICA', style: { backgroundColor: '#1cf100' } },
  { option: 'DIARIO ONLINE', style: { backgroundColor: 'white' } },
];

export default function Interaction2() {
  const [mustSpin, setMustSpin] = useState(false);
  const [spinCount, setSpinCount] = useState(0);  // Estado para contar los giros
  const [prizeNumber, setPrizeNumber] = useState(0);
  const navigate = useNavigate();  // Para redirigir a la pantalla de preguntas
  const [userInfo, setUserInfo] = useState({});
  const rotationOffset = 9; // Desfase en número de sectores (ajusta según el ángulo de rotación visual)
  const [answered, setAnswered] = useState([]);

  const [showdisabledSpinModal, setShowDisabledSpinModal] = useState(false)

  // Obtener userInfo desde el localStorage al cargar la página
  useEffect(() => {
    const uinfo = localStorage.getItem('userInfo');
    if (uinfo) {
      setUserInfo(JSON.parse(uinfo));
    }
    setAnswered(localStorage.getItem("spinAnswered") ? JSON.parse(localStorage.getItem("spinAnswered")) : [])
    let localStorageSpinCount = localStorage.getItem('spinCount');
    localStorageSpinCount = Number(localStorageSpinCount)
    if (isNaN(localStorageSpinCount)) {
      setSpinCount(0)
    } else {
      setSpinCount(localStorageSpinCount)
    }
  }, []);

  const handleSpinClick = (sum) => {
    if (!mustSpin && spinCount < 5) {  // Asegurarse de que no está girando y que el conteo es menor que 5
      let randomIndex = Math.floor(Math.random() * data.length);
      console.log(answered)
      while (answered.includes(randomIndex - 1) || (randomIndex - 1) == 3){
        console.log("otra pregunta", randomIndex - 1)
        randomIndex = Math.floor(Math.random() * data.length);
      }
      localStorage.setItem("spinAnswered", JSON.stringify([...answered, (randomIndex - 1)]))
      setAnswered([...answered, (randomIndex - 1)])
      
      setPrizeNumber(randomIndex);
      setMustSpin(true);

      localStorage.setItem('spinCount', spinCount + 1);
      setSpinCount(prevCount => prevCount + 1);


    } else if (spinCount >= 5) {
      setShowDisabledSpinModal(true);  // Muestra modal cuando se alcanzan 5 giros
    }
  };

  const handleWheelStop = () => {
    setTimeout(() => {
      setMustSpin(false);

      // Ajustamos el resultado considerando la rotación visual
      const adjustedIndex = (prizeNumber + rotationOffset) % data.length;
      const selectedOption = data[adjustedIndex].option;

      // Redirigir a la pantalla de preguntas según la opción seleccionada
      const encodedOption = encodeURIComponent(selectedOption); // Asegúrate de codificar bien la URL
      navigate(`/question/${encodedOption}`);
    }, 1000); // Espera 1 segundo antes de ejecutar la acción
  };

  return (
    <div className="content flex" style={{ overflow: 'hidden', flexDirection: 'column' }}>
      <Row className='pt-4' style={{ flex: '0 0 auto' }}>
        <Col className='flex j-center'>
          <div className='fitContentWidth'>
            <img style={{ height: '35px' }} src="/imgs/media_day_logo.png" />
            <p className='txtWhite mt-1 fs-18 me-1 mb-0' style={{ textAlign: 'right' }}>let's play!</p>
          </div>
        </Col>
      </Row>
      <Row style={{ flex: '0 0 40%' }}>
        <Col className='flex j-center a-center'>
          <div>
            <Button
              className='gradient-back noBorder buttonSpin ps-3 pe-3'
              style={{ borderRadius: '2px', width: '200px', opacity: spinCount >= 5 ? 0.7 : 1 }}
              onClick={handleSpinClick}
            // Deshabilitar si se alcanzan 5 giros
            >
              <div className='txtBlack fw800 fs-26'>
                ¡GIREMOS!
              </div>
            </Button>

          </div>

        </Col>

      </Row>
      <Row className='mt-3' style={{ flex: '1', marginBottom: '-45%' }}>
        <div className='wheel-container'>
          <div className="wheel-wrapper">
            <div className="wheel-marker" style={{ position: 'relative' }}></div>

            <Wheel
              mustStartSpinning={mustSpin}
              prizeNumber={prizeNumber}
              data={data}
              onStopSpinning={handleWheelStop}
              outerBorderColor={['transparent']}
              outerBorderWidth={0}
              innerRadius={20}
              radiusLineColor={['#000']}
              radiusLineWidth={0}
              textColors={['#000']}
              fontSize={18}
              spinDuration={0.3}
              fontFamily={'Ploni, sans-serif'}
              textDistance={50}
            />

            <div className="fitContentWidth" style={{ position: 'absolute', top: '50%', left: '50%', zIndex: '100', transform: 'translate(-50%, -50%)' }}>
              <img src="/imgs/star.png" style={{ height: '50px' }} />
            </div>

          </div>
        </div>
      </Row>

      <Modal
        show={showdisabledSpinModal}
        centered
        onHide={() => setShowDisabledSpinModal(false)}
        backdrop="static"
      >
        <Modal.Body
          style={{
            backgroundColor: '#0d0d0d', // Fondo negro intenso
            color: '#4caf50', // Verde más suave, similar a un tono esmeralda
            textAlign: 'center',
            padding: '20px',
            borderRadius: '2px',
          }}
        >
          <h3
            style={{
              fontSize: '28px',
              fontWeight: 'bold',
              marginBottom: '15px',
              color: '#1cf100', // Título en verde vibrante
            }}
          >
            Has alcanzado el número máximo de giros
          </h3>

        </Modal.Body>
        <Modal.Footer
          style={{
            backgroundColor: '#0d0d0d', // Fondo negro intenso
            borderTop: 'none',
            justifyContent: 'center', // Centra el contenido del footer
          }}
        >
          <Button
            style={{
              backgroundColor: '#1cf100',
              borderColor: '#1cf100',
            }}
            onClick={() => setShowDisabledSpinModal(false)}
          >
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
}

