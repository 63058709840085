import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { doc, collection, addDoc, getDoc } from "firebase/firestore";
import { db } from '../../../firebase';
import '../../../App.css';

const JoinGame = ({ onJoinGame }) => {
    const [playerName, setPlayerName] = useState('');
    const [gameCode, setGameCode] = useState(''); // Se puede agregar un campo para el código del juego
    const navigate = useNavigate();

    const handleJoinGame = async (e) => {
        e.preventDefault();

        // Verificar si el código del juego existe en la base de datos
        const gameRef = doc(db, 'gameState', gameCode); // Ajusta el path según tu base de datos
        const gameSnapshot = await getDoc(gameRef);

        if (gameSnapshot.exists()) {
            // Agregar el jugador a la colección de jugadores dentro del juego
            const playerData = {
                firstName: playerName,
                score: 0 // Inicializar puntuación en 0
            };

            try {
                const playersRef = collection(db, 'gameState', gameCode, 'players');
                await addDoc(playersRef, playerData);
                console.log('Jugador añadido correctamente');

                // Navegar a la pantalla de juego
                if (onJoinGame) onJoinGame(gameCode); // Ejecutar callback si está definido
                navigate('/game'); // Cambiar a la ruta de la sala del juego
            } catch (error) {
                console.error('Error al unirse al juego:', error);
            }
        } else {
            alert('Código de juego no válido');
        }
    };

    return (
        <Form onSubmit={handleJoinGame} className="join-game-form">
            <Form.Group controlId="formGameCode">
                <Form.Label>Código del Juego</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Introduce el código del juego"
                    value={gameCode}
                    onChange={(e) => setGameCode(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group controlId="formPlayerName">
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Introduce tu nombre"
                    value={playerName}
                    onChange={(e) => setPlayerName(e.target.value)}
                    required
                />
            </Form.Group>

            <Button variant="primary" type="submit" className="mt-3">
                Unirse al Juego
            </Button>
        </Form>
    );
};

export default JoinGame;
