import React, { useState, useEffect } from 'react';
import { Button, Col, Row, FormControl } from 'react-bootstrap';
import { doc, getDoc, addDoc, collection, onSnapshot } from "firebase/firestore";
import { db, saveUserResponse } from '../../../firebase';
import 'bootstrap/dist/css/bootstrap.min.css';

const Interaction5 = () => {
    const [images, setImages] = useState({});
    const [prompts, setPrompts] = useState({});
    const [loading, setLoading] = useState(false);
    const [selectedCelebrity, setSelectedCelebrity] = useState(null);
    const [showEditedImage, setShowEditedImage] = useState(false);
    const [userInfo, setUserInfo] = useState({});

    const celebrities = [
        { name: "Messi", image: "/imgs/messi.png" },
        { name: "Pampita", image: "/imgs/pampita.png" },
        { name: "Susana", image: "/imgs/susana.png" },
        { name: "Colapinto", image: "/imgs/colapinto.png" },
        { name: "Harry Styles", image: "/imgs/harry.png" },
        { name: "Duki", image: "/imgs/duki.png" },
        { name: "Taylor Swift", image: "/imgs/taylor.png" },
        { name: "Santiago Maratea", image: "/imgs/maratea.png" },
        { name: "Tini", image: "/imgs/tini.png" },
        { name: "Wanda Nara", image: "/imgs/wanda.png" }
    ];

    useEffect(() => {
        const uinfo = localStorage.getItem('userInfo');
        if (uinfo) {
          setUserInfo(JSON.parse(uinfo));
        }
        
      }, []);

    const saveImage = async (img) => {
        try{
            await addDoc(collection(db, 'interaction5'), {img: img, user: userInfo.nickname ? userInfo.nickname : " "});
        }catch(err){

        }
    }

    const fetchImage = async () => {
        setLoading(true);
        setShowEditedImage(false);
        const prompt = prompts[selectedCelebrity];

        try {
            const response = await fetch('https://ar-caba-sv13.seconline.net.ar:16643/edit-image', {

                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: selectedCelebrity,
                    prompt: prompt
                })
            });

            const data = await response.json();

            try{
                saveImage(data.edited_image_url);
            }catch(err){

            }

            const img = new Image();
            img.src = data.edited_image_url;

            const imageLoaded = new Promise((resolve, reject) => {
                img.onload = () => resolve();
                img.onerror = (err) => reject(err);
            });

            const delay = new Promise((resolve) => {
                setTimeout(resolve, 3000);
            });

            await Promise.all([imageLoaded, delay]);

            setImages(prevImages => ({
                ...prevImages,
                [selectedCelebrity]: data.edited_image_url
            }));
            setShowEditedImage(true);
            setLoading(false);

        } catch (error) {
            console.error(`Error al generar la imagen para ${selectedCelebrity}: `, error);
            setLoading(false);
        }
    };

    const handlePromptChange = (value) => {
        setPrompts(prevPrompts => ({
            ...prevPrompts,
            [selectedCelebrity]: value
        }));
    };

    const handleCelebrityClick = (celebrity) => {
        setSelectedCelebrity(celebrity);
        setShowEditedImage(false);
        setLoading(false);
    };

    const handleBackClick = () => {
        setSelectedCelebrity(null);
        setShowEditedImage(false);
        setLoading(false);
    };

    return (
        <div className="container text-center" style={{ backgroundColor: 'black', color: 'white', minHeight: '100dvh', padding: '0 40px' }}>
            {/* Pantalla de selección de celebridades */}
            {!selectedCelebrity ? (
                <>
                    {/* Logo y Let's play alineados como en la ventana emergente */}
                    <Row className="pt-4">
                        <Col className="d-flex flex-column align-items-center">

                            <img style={{ height: '50px' }} src="/imgs/media_day_logo.png" alt="Logo" />
                            <p className="fw600 mt-1" style={{ fontSize: '20px', textAlign: 'right', width: '35%', transform: 'translateX(40%)' }}>
                                Let's play!
                            </p>

                        </Col>
                    </Row>

                    {/* Título "Gen IA" y selección de celebridades */}
                    <Row className="mt-3">
                        <Col>
                            <h2 style={{ fontWeight: "bold", fontSize: 45 }}>GEN IA</h2>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col>
                            <h3 >Elegí un personaje</h3>
                        </Col>
                    </Row>
                    <Row className="mt-3 justify-content-center g-0">
                        {celebrities.map((celebrity) => (
                            <Col xs={6} md={4} lg={3} key={celebrity.name} className="p-2">
                                <div
                                    className="celebrity-card"
                                    style={{
                                        cursor: 'pointer',
                                        border: 'none',
                                        padding: '0',
                                        margin: '0',
                                        width: '95%',
                                        aspectRatio: '1',
                                        borderRadius: '15px',
                                        overflow: 'hidden'
                                    }}
                                    onClick={() => handleCelebrityClick(celebrity.name)}
                                >
                                    <img
                                        src={celebrity.image}
                                        alt={`Imagen de ${celebrity.name}`}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover'
                                        }}
                                    />
                                </div>
                            </Col>
                        ))}
                    </Row>
                </>
            ) : (
                // Pantalla emergente del famoso seleccionado
                <Row className="mt-5 justify-content-center">
                    <Col xs={12}>
                        <div className="d-flex flex-column align-items-center" style={{ marginTop: '30px' }}>
                            {/* Logo */}
                            <img style={{ height: '50px' }} src="/imgs/media_day_logo.png" alt="Logo" />
                            <p className="fw600 mt-1" style={{ fontSize: '20px', textAlign: 'right', width: '35%', transform: 'translateX(40%)' }}>
                                Let's play!
                            </p>

                            {/* Título "GEN IA" igual al de la pantalla de famosos */}
                            <h2 style={{ marginTop: '10px', fontWeight: "bold" }}>GEN IA</h2>
                        </div>

                        {/* Nombre del famoso */}
                        <h1 style={{ fontSize: '45px', marginBottom: '3px' }}>{selectedCelebrity}</h1>

                        {/* Imagen con margen horizontal aumentado */}
                        <div
                            className="card mb-4 mx-auto"
                            style={{
                                background: 'none',
                                border: 'none',
                                position: 'relative',
                                width: '70%', // 30% más de margen horizontal (100% - 30% * 2)
                                paddingBottom: '70%', // Mantiene el aspecto cuadrado
                                overflow: 'hidden'
                            }}
                        >
                            <img
                                src={celebrities.find(celebrity => celebrity.name === selectedCelebrity).image}
                                alt={`Imagen de ${selectedCelebrity}`}
                                className="card-img-top"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    zIndex: 1,
                                }}
                            />

                            {images[selectedCelebrity] && showEditedImage && (
                                <img
                                    src={images[selectedCelebrity]}
                                    alt={`Imagen editada de ${selectedCelebrity}`}
                                    className="card-img-top"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        zIndex: 2,
                                    }}
                                />
                            )}

                            {loading && (
                                <div
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        background: 'linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)',
                                        backgroundSize: '200% 100%',
                                        animation: 'skeleton-loading 1.5s infinite',
                                        borderRadius: '15px',
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        zIndex: 3,
                                    }}
                                />
                            )}
                        </div>

                        {/* Input con botón de enviar dentro y margen horizontal */}
                        <div style={{ width: '70%', margin: '0 auto', position: 'relative', marginBottom: '1rem' }}>
                            <FormControl
                                type="text"
                                placeholder={`Transformá al personaje con IA...`}
                                value={prompts[selectedCelebrity] || ''}
                                onChange={(e) => handlePromptChange(e.target.value)}
                                style={{ paddingRight: '45px' }} // Espacio para el icono
                            />
                            
                            {/* SVG de checkmark directamente en JSX con color verde */}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                                style={{
                                    width: '24px',
                                    height: '24px',
                                    position: 'absolute',
                                    top: '50%',
                                    right: '10px',
                                    transform: 'translateY(-50%)',
                                    cursor: 'pointer',
                                    fill: 'lime' // Aplica el color verde
                                }}
                                onClick={fetchImage}
                            >
                                <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/>
                            </svg>

                        </div>

                        {/* Botón de volver debajo del input */}
                        <div className="text-left" style={{ width: '70%', margin: '0 auto' }}>
                            <Button variant="link" onClick={handleBackClick}>
                                ← Volver
                            </Button>
                        </div>
                    </Col>
                </Row>
            )}
        </div>
    );
};

export default Interaction5;
