import React, { useState, useEffect } from 'react';
import { doc, updateDoc, getDoc, query, collection, orderBy, limit, getDocs, onSnapshot } from 'firebase/firestore';
import { db } from '../../../firebase';
import { Button } from 'react-bootstrap';

export default function Interaction7() {
    const [winners, setWinners] = useState([]);
    const [remainingPlayers, setRemainingPlayers] = useState([]);
    const prizes = [
        "Estadía de 5 noches en Howard Johnson para dos personas",
        "Combo de tres electrodomésticos",
        "Estadía de una semana en Solanas Punta del Este para dos personas",
        "Smart TV Noblex 50”",
        "Parlante portátil Sony",
        "Celular Samsung S24 FE",
        "Asistente virtual Alexa Amazon"
    ];
    const [currentPrizeIndex, setCurrentPrize] = useState(-1)

    useEffect(() => {
        let unsubscribe; // Para almacenar la referencia a onSnapshot

        // Función para cargar los 30 primeros jugadores del ranking
        const fetchRanking = async () => {
            if (remainingPlayers.length > 0) {
                // Si ya tienes los jugadores, no vuelvas a hacer la consulta
                return;
            }

            try {
                const q = query(
                    collection(db, 'user_responses'),
                    orderBy('score', 'desc'),
                    limit(30) // Limita a los primeros 30 documentos
                );
                const querySnapshot = await getDocs(q);

                const data = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                // Actualiza el estado con los datos obtenidos
                setRemainingPlayers(data);
            } catch (error) {
                console.error('Error al obtener los datos del ranking:', error);
            }
        };

        // Función para manejar los cambios en el documento `gameState`
        const handleGameStateChange = (doc) => {
            if (doc.exists()) {
                const gameStateData = doc.data();

                // Verificar si la interacción activa es la correcta
                if (gameStateData.activeInteraction !== 7) {
                    return;
                }

                // Verifica si `currentPrizeIndex` está definido



                // Verifica si `lotteryWinners` ya está disponible y actualiza el estado de winners
                if (gameStateData.lotteryWinners) {
                    setWinners(gameStateData.lotteryWinners);
                }

            } else {
                console.warn('gameState document does not exist');
            }
        };

        // Fetch ranking si no está cargado
        fetchRanking();

        // Suscribirse a los cambios en gameState
        const gameStateDocRef = doc(db, 'gameState', 'current');
        unsubscribe = onSnapshot(gameStateDocRef, handleGameStateChange);

        return () => {
            // Limpiar la suscripción
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, [remainingPlayers, winners]);


    const startLottery = async (prizeIndex) => {
        setCurrentPrize(prizeIndex);
        console.log(remainingPlayers)
        if (winners.length >= 7) {
            alert('Ya salieron los 7 ganadores.');
            return;
        }

        if (remainingPlayers.length === 0) {
            alert('No hay suficientes jugadores para continuar el sorteo.');
            return;
        }

        // Elegir aleatoriamente un jugador entre los que quedan
        const randomIndex = Math.floor(Math.random() * remainingPlayers.length);
        const winner = remainingPlayers[randomIndex];

        // Asegúrate de que el premio existe
        console.log(prizeIndex)
        const prize = prizes[prizeIndex] || null;

        const selectedWinner = {
            ...winner,
            prize // Asignar el premio correspondiente o null si no existe
        };

        console.log(selectedWinner);

        // Actualizar la lista de ganadores y remover el ganador de la lista de jugadores restantes
        setRemainingPlayers(remainingPlayers.filter((_, index) => index !== randomIndex));

        // Actualizar en Firestore para mostrar el ganador actual
        if (winners && selectedWinner) {
            const gameStateRef = doc(db, 'gameState', 'current');
            console.log(gameStateRef);
            console.log(selectedWinner);
            console.log(winners);

            // Verificar que no hay valores undefined
            const updatedWinners = [...winners, selectedWinner].filter(w => w !== undefined);

            try {
                await updateDoc(gameStateRef, {
                    lotteryWinners: updatedWinners, // Añadir el nuevo ganador, filtrando undefined
                    lotteryStatus: updatedWinners.length === 7 ? 'finalizado' : 'realizando'
                });
            } catch (error) {
                console.error('Error actualizando el documento:', error);
            }
        } else {
            console.error('Error: winners or selectedWinner is undefined');
        }
    };

    return (
        <div>
            <Button onClick={() => startLottery(currentPrizeIndex + 1)} disabled={winners.length == 7}>Siguiente premio</Button>

        </div>
    );
}
