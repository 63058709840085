import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { collection, doc, getDoc, getDocs, onSnapshot, query, where } from "firebase/firestore";
import { db, saveUserResponse } from '../../../firebase';
import '../../../App.css';

const Login = ({ onLogin, setGameActive }) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [position, setPosition] = useState('');
    const [birthDate, setBirthDate] = useState('');
    const [gender, setGender] = useState('');
    const [waitingForHost, setWaitingForHost] = useState(false);
    const [nickname, setNickname] = useState('')

    const navigate = useNavigate();

    useEffect(() => {

    }, []);

    const [isNicknameValid, setNicknameValid] = useState(true)

    const validateNickname = async (nickname) => {
        try {
            const q = query(
                collection(db, 'user_responses'),
                where('nickname', '==', nickname)
            );

            const querySnapshot = await getDocs(q);
            console.log(querySnapshot)

            // Si se encuentra al menos un documento, significa que ya existe un usuario con el mismo nickname
            console.log(!querySnapshot.empty)
            if (!querySnapshot.empty) {
                setNicknameValid(false)
                return false;
            }
            return true;
        } catch (error) {
            console.error('Error al validar el nickname:', error);
            return { valid: false, message: 'Error al validar el nickname.' };
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const correctGameCode = '8888'; // Código de acceso
        const isNickNameValid = await validateNickname(nickname)
        if (!isNickNameValid) {
            /* console.log("El nickname ya está en uso")
            alert('El nickname ya está en uso. Intente de nuevo con otro.') */
            return;
        }

        if (firstName && lastName && nickname) {
            const userData = {
                gameCode: 8888,
                firstName,
                lastName,
                email,
                company,
                position,
                birthDate,
                gender,
                nickname
            };

            try {
                await saveUserResponse(userData);
                console.log('Usuario guardado correctamente');
                onLogin(userData);

                localStorage.setItem('userInfo', JSON.stringify(userData));

                //setWaitingForHost(true);
                navigate('/waiting')
            } catch (error) {
                console.error('Error al guardar el usuario:', error);
            }
        } else {
            alert('Campos incompletos');
        }
    };

    return (
        <div className="login-container content txtWhite ps-4 pe-4 fs-14 pb-4 fw200">
            <Row className='pt-3 ps-2 pe-2 mb-4'>
                <Col className='flex a-center'>
                    <img style={{ height: '30px' }} src={'/imgs/caam.png'} />
                </Col>
                <Col className='endRow a-center'>
                    <img style={{ height: '50px' }} src={'/imgs/md_sticker.png'} />
                </Col>
            </Row>
            <Row>
                {!waitingForHost ? (<>
                    <div className='fs-18'>Por favor completá los siguientes datos:</div>
                    <Form onSubmit={handleSubmit}>
                        {/* 
                    <Form.Group controlId="formGameCode" className='mt-2'>
                        <Form.Label className="mb-0">Código del Juego</Form.Label>
                        <Form.Control
                            type="text"
                            value={gameCode}
                            onChange={(e) => setGameCode(e.target.value)}
                            required
                            className="gradient-back noBorder"
                        />
                    </Form.Group> */}
                        <Form.Group controlId="nickName" className='mt-2'>
                            <Form.Label className="mb-0">Nickname</Form.Label>
                            <Form.Control
                                type="text"
                                value={nickname}
                                onChange={(e) => setNickname(e.target.value)}
                                required
                                className="gradient-back noBorder"
                                isInvalid={!isNicknameValid}
                            />
                            <Form.Control.Feedback type="invalid" style={{color:'#06dae5'}}>
                                Intentá con otro nickname
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="formFirstName" className='mt-2'>
                            <Form.Label className="mb-0">Nombre</Form.Label>
                            <Form.Control
                                type="text"
                                /* placeholder="Introduce tu nombre" */
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                required
                                className="gradient-back noBorder"
                            />
                        </Form.Group>

                        <Form.Group controlId="formLastName" className='mt-2'>
                            <Form.Label className="mb-0">Apellido</Form.Label>
                            <Form.Control
                                type="text"
                                /* placeholder="Introduce tu apellido" */
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                required
                                className="gradient-back noBorder"
                            />
                        </Form.Group>



                        <Form.Group controlId="formGender" className='mt-2'>
                            <Form.Label className="mb-0">Género</Form.Label>
                            <Form.Control
                                as="select"
                                value={gender}
                                onChange={(e) => setGender(e.target.value)}
                                className="gradient-back noBorder"
                            >
                                <option value="">Seleccione</option>
                                <option value="masculino">Masculino</option>
                                <option value="femenino">Femenino</option>
                                <option value="x">X</option>
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="formBirthDate" className='mt-2'>
                            <Form.Label className="mb-0">Fecha de Nacimiento</Form.Label>
                            <Form.Control
                                type="date"
                                value={birthDate}
                                onChange={(e) => setBirthDate(e.target.value)}
                                min="1930-01-01"
                                max="2024-12-31"
                                className="gradient-back noBorder"
                            />
                        </Form.Group>

                        <Form.Group controlId="formEmail" className='mt-2'>
                            <Form.Label className="mb-0">Correo Electrónico</Form.Label>
                            <Form.Control
                                type="email"
                                /* placeholder="Introduce tu correo electrónico" */
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                className="gradient-back noBorder"
                            />
                        </Form.Group>

                        <Form.Group controlId="formCompany" className='mt-2'>
                            <Form.Label className="mb-0">Empresa</Form.Label>
                            <Form.Control
                                type="text"
                                /* placeholder="Introduce la empresa en la que trabajas" */
                                value={company}
                                onChange={(e) => setCompany(e.target.value)}
                                className="gradient-back noBorder"
                            />
                        </Form.Group>

                        <Form.Group controlId="formPosition" className='mt-2'>
                            <Form.Label className="mb-0">Cargo</Form.Label>
                            <Form.Control
                                type="text"
                                /* placeholder="Introduce tu cargo" */
                                value={position}
                                onChange={(e) => setPosition(e.target.value)}
                                className="gradient-back noBorder"
                            />
                        </Form.Group>

                        <Button variant="light" type="submit" className="w-100 mt-4 radiusButton gradient-back noBorder">
                            <div className='fw600'>Unirme</div>
                        </Button>
                    </Form>
                </>) : (

                    <div className="text-center">
                        <Row style={{ height: 'calc(82dvh - 100px)' }} className='flex a-center'>
                            <div>

                                <img style={{ width: '85vw' }} src="/imgs/media_day_logo.png" className='mb-4' />
                                <p style={{ textAlign: 'center', fontSize: '28px' }} className='fw600 mb-4'>¡Gracias, {firstName}!</p>
                                <p style={{ textAlign: 'center' }} className='fs-22 fw500 mb-1'>Cuando el Host lo indique, volvé a ingresar para comenzar a jugar.</p>

                            </div>
                        </Row>
                        <Row style={{ height: '18dvh' }}>
                            <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} className='txtWhite'>
                                <p className='txtWhite mb-1' style={{ textAlign: 'left' }}>Powered by</p>
                                <div style={{ height: 'fit-content', textAlign: 'left' }} >
                                    <img style={{ width: '110px', fill: 'white' }} src="/imgs/logo_logan.png" />
                                </div>
                            </Col>
                            <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <p className='txtWhite mb-1' style={{ textAlign: 'right' }}>Sponsor <span className='fw800'>Oro</span></p>
                                <div style={{ height: 'fit-content' }} className='endRow'>
                                    <img style={{ width: '90px' }} src="/imgs/ma_white.png" />
                                </div>
                            </Col>
                        </Row>
                        {/* <h5>Esperando que el host active el juego...</h5> */}

                    </div>
                )}
            </Row>

            {/* Mostrar el botón de "Usar Usuario de Prueba" cuando el formulario está visible */}
            {/* {!waitingForHost && (
                <Button variant="secondary" onClick={fillTestUser} className="w-100 mt-3">
                    Usar Usuario de Prueba
                </Button>
            )} */}

        </div>
    );
};

export default Login;
