import React, { useEffect, useState } from 'react';
import { collection, onSnapshot } from "firebase/firestore";
import { db } from '../../../firebase';
import '../../../App.css';

const GameRoom = ({ gameCode }) => {
    const [players, setPlayers] = useState([]);

    useEffect(() => {
        const playersRef = collection(db, 'gameState', gameCode, 'players');

        // Escuchar cambios en la lista de jugadores
        const unsubscribe = onSnapshot(playersRef, (snapshot) => {
            const playerList = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setPlayers(playerList);
        });

        return () => unsubscribe();
    }, [gameCode]);

    return (
        <div className="game-room">
            <h2>Código del Juego: {gameCode}</h2>
            <h3>Jugadores Conectados:</h3>
            <ul>
                {players.map(player => (
                    <li key={player.id}>
                        {player.firstName} - Puntos: {player.score}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default GameRoom;
