import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';
import { Spinner } from 'react-bootstrap';

const InteractionWatcher = () => {
    const navigate = useNavigate();
    const locationPath = useLocation().pathname
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const gameStateDocRef = doc(db, 'gameState', 'current');
        const unsubscribe = onSnapshot(gameStateDocRef, (doc) => {
            if (doc.exists()) {
                const gameStateData = doc.data();
                console.log("Active interaction", gameStateData.activeInteraction)

                setIsLoading(false)

                if (gameStateData.showRanking) {
                    navigate('/ranking');
                    return;
                }

                const userInfo = localStorage.getItem('userInfo');

                if (!userInfo) {
                    navigate('/inicio');

                    return;
                }

                if (gameStateData.showMenu) {
                    navigate('/menu');
                    return;
                }
                console.log(locationPath)



                if (!gameStateData.interactionComplete && gameStateData.activeInteraction >= 0) {
                    console.log("1")
                    switch (gameStateData.activeInteraction) {
                        case 0:
                            console.log("Redirecciono a waiting room");
                            navigate('/waiting');
                            break;
                        case 1:
                            console.log("Redirecciono a interaccion 1");
                            navigate('/interaction1');
                            break;
                        case 2:
                            console.log("Redirecciono a interaccion 2");
                            navigate('/interaction2');
                            break;
                        case 3:
                            console.log("Redirecciono a interaccion 3");
                            navigate('/interaction3');
                            break;
                        case 4:
                            console.log("Redirecciono a interaccion 4");
                            navigate('/interaction4');
                            break;
                        case 5:
                            console.log("Redirecciono a interaccion 5");
                            navigate('/interaction5');
                            break;
                        case 6:
                            console.log("Redirecciono a interaccion 6");
                            navigate('/interaction6');
                            break;
                        case 7:
                            console.log("Redirecciono a interaccion 7");
                            navigate('/interaction7');
                            break;
                        case 8:
                            console.log("Redirecciono a interaccion 8");
                            navigate('/survey');
                            break;
                    }
                } else {
                    //navigate('/menu');
                }
            }
        });

        return () => {
            console.log("Limpieza: quitando la marca de esta pestaña.");
            sessionStorage.removeItem('interactionTab');
            unsubscribe();
        };
    }, [navigate]);

    if (isLoading) {
        return <div className='flex j-center content a-center'>
            <Spinner animation="border" role="status" variant="" style={{ color: "#1cf100" }}>
                <span className="visually-hidden"></span>
            </Spinner>
        </div>;
    }

    return null;
};

export default InteractionWatcher;
