import { initializeApp } from 'firebase/app';
import { getFirestore, doc, setDoc, collection, addDoc } from 'firebase/firestore';

// Configuración de Firebase
const firebaseConfig = {
    apiKey: "AIzaSyCfFr5HQpPBfttRsekKlx6R1Ax-KlmF1jU",
    authDomain: "nuevo-kahoot.firebaseapp.com",
    projectId: "nuevo-kahoot",
    storageBucket: "nuevo-kahoot.appspot.com",
    messagingSenderId: "188410632114",
    appId: "1:188410632114:web:1f9a920f26abc27414f6ab",
};

// Inicializa la aplicación de Firebase
const app = initializeApp(firebaseConfig);

// Inicializa Firestore
const db = getFirestore(app);

// Función para guardar los datos de usuario en la colección /user_responses
const saveUserResponse = async (userData) => {
    try {
        // Usar el email como ID único del documento
        const userRef = doc(db, 'user_responses', userData.email);

        // Guardar los datos del usuario
        await setDoc(userRef, userData, { merge: true });
        
        console.log("Usuario guardado correctamente en la colección /user_responses");
    } catch (error) {
        console.error("Error al guardar el usuario en la base de datos:", error);
    }
};

// Función para guardar las respuestas de los usuarios
const saveUserAnswer = async (userEmail, questionData) => {
    try {
        // Guardar la respuesta en la subcolección 'answers' del usuario
        const answersRef = collection(db, 'user_responses', userEmail, 'answers');
        await addDoc(answersRef, questionData);
        
        console.log("Respuesta guardada correctamente");
    } catch (error) {
        console.error("Error al guardar la respuesta:", error);
    }
};

// Exporta la instancia de Firestore y las funciones
export { db, saveUserResponse, saveUserAnswer };
